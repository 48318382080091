export const getStorage = (name) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(name) || '' : '';
  try {
    return JSON.parse(data);
  } catch (err) {
    return data;
  }
};

export const setStorage = (name, value) => {
  const stringify = typeof value !== 'string' ? JSON.stringify(value) : value;
  return localStorage.setItem(name, stringify);
};

export const deleteStorage = (name) => {
  return localStorage.removeItem(name);
};

export const clearStorage = () => {
  return localStorage.clear();
};
