import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { redirect } from 'react-router-dom';
import { HOME_ROUTE } from 'routes';

const initialState = {
  isLoggedIn: false,
  logging: false,
  loading: false,
  emailRegister: '',
  emailForgotPass: '',
  otp: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state) {
      state.logging = true;
    },
    loginSuccess(state) {
      state.isLoggedIn = true;
      state.logging = false;
    },
    loginFailed(state) {
      state.logging = false;
    },

    register(state) {
      state.loading = true;
    },
    registerSuccess(state, action) {
      state.emailRegister = action.payload;
      state.loading = false;
    },
    registerFailed(state) {
      state.loading = false;
    },
    clearEmailRegister(state) {
      state.emailRegister = '';
    },
    checkValidOTP(state) {
      state.loading = true;
    },
    confirmEmailOtp(state) {
      state.loading = true;
    },
    confirmEmailOtpSuccess(state, action) {
      state.loading = false;
      state.otp = action.payload;
    },
    confirmEmailOtpFailed(state) {
      state.loading = false;
    },

    resendOtpEmail(state) {
      state.loading = true;
    },
    resendOtpEmailSuccess(state) {
      state.loading = false;
    },
    resendOtpEmailFailed(state) {
      state.loading = false;
    },
    clearOtp(state) {
      state.otp = '';
    },
    forgotPassword(state) {
      state.loading = true;
    },
    forgotPasswordSuccess(state, action) {
      state.emailForgotPass = action.payload;
      state.loading = false;
    },
    forgotPasswordFailed(state) {
      state.loading = false;
    },
    clearEmailForgotPass(state) {
      state.emailForgotPass = '';
    },

    createPassByOTP(state) {
      state.loading = true;
    },
    createPassByOTPSuccess(state) {
      state.loading = false;
      toast.success(i18next.t('common:update-pass-success'));
    },
    createPassByOTPFailed(state) {
      state.loading = false;
    },

    logout(state) {
      redirect(HOME_ROUTE);
      state.isLoggedIn = false;
    },
    clearData(state) {
      state.emailRegister = '';
      state.emailForgotPass = '';
      state.otp = '';
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Selectors
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectIsLogging = (state) => state.auth.logging;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
