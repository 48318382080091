import styled from 'styled-components';

export const StoreStyled = styled.div`
  position: relative;
  width: 100%;
  .w_0 {
    width: 0px !important;
  }
  .show-right-panel {
    position: absolute;
    right: 0px;
    top: -10px;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 110;
    @media only screen and (max-width: 768px) {
      width: 100%;
      max-width: 440px;
    }
    .right-panel {
      width: 390px;
      background-color: var(--store-search-background-2);
      box-shadow: var(--store-search-boxshadow);
      position: relative;
      border-radius: 0;
      @media only screen and (max-width: 768px) {
        width: 100% !important;
        max-width: 440px;
      }
    }
    @media only screen and (max-width: 1440px) {
      border-radius: 0px !important;
    }
    .hideRight {
      width: 0px !important;
      padding: 0px;
    }
  }

  .icon_show_right_panel {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translate(0%, -50%);
    width: 50px;
    height: 80px;
    background-color: rgba(43, 46, 59, 70%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px 0px 0px 26px;
    cursor: pointer;
  }

  .icon_show_right_panel-mb {
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translate(0%, -50%);
    width: 50px;
    height: 80px;
    background-color: rgba(43, 46, 59, 70%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 26px 0px 0px 26px;
    cursor: pointer;
    cursor: pointer;
    @media only screen and (max-width: 440px) {
      left: 0px;
      border-radius: 0px 26px 26px 0px;
      transform: translate(0%, 0%);
    }
  }

  .justify-content-center {
    display: flex;
    justify-content: center;
  }
  .note-90 {
    width: 90px;
    margin: auto;
  }
  .min-width-25 {
    min-width: 25px;
    background-color: white !important;
    right: 0px;
  }
  .min-width-30 {
    min-width: 30px;
  }
  .min-width-40 {
    min-width: 40px;
  }
  .width-40-fixed {
    width: 40px;
    background-color: white !important;
  }
  .fixed-column-right {
    width: 40px;
    background-color: white !important;
  }
  .min-width-50 {
    min-width: 50px;
  }
  .min-width-55 {
    min-width: 55px;
  }
  .width-60-fixed {
    width: auto;
    background-color: white !important;
  }
  .min-width-60 {
    min-width: 60px;
  }
  .min-width-65 {
    min-width: 65px;
  }
  .min-width-70 {
    min-width: 70px;
  }
  .min-width-80 {
    min-width: 80px;
  }
  .min-width-85 {
    min-width: 85px;
  }
  .min-width-90 {
    min-width: 90px;
  }
  .min-width-100 {
    min-width: 100px;
  }
  .min-width-110 {
    min-width: 110px;
  }
  .min-width-115 {
    min-width: 115px;
  }
  .min-width-120 {
    min-width: 120px;
  }
  .min-width-130 {
    min-width: 130px;
  }
  .min-width-140 {
    min-width: 140px;
  }
  .min-width-150 {
    min-width: 150px;
  }
  .min-width-160 {
    min-width: 160px;
  }
  .min-width-180 {
    min-width: 180px;
  }
  .min-width-200 {
    min-width: 200px;
  }
  .min-width-215 {
    min-width: 215px;
  }
  .min-width-290 {
    min-width: 290px;
  }
  .pay-status-style {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .text-style {
    min-width: 50px;
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: var(--table-cell-text);
    @media only screen and (max-width: 1920px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
    }
  }
  .hided {
    color: var(--color-text-input-table-lading) !important;
  }

  .status-delivery {
    &__delivered {
      svg path {
        fill: var(--color-primary);
      }
      div {
        color: var(--color-primary);
      }
    }
  }
  .table-view {
    width: 0px;
    /* overflow: hidden; */
    height: 100%;
  }
  .full-page {
    height: 100%;
    display: flex;
    gap: 16px;
  }
  .right-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    width: 430px;
    background-color: var(--backgroud-main);
    border-radius: 10px 0 0 10px;
    box-shadow: var(--right-panel-box-shadow);
    border-right: 5px solid rgba(254, 143, 1, 1);
    @media only screen and (max-width: 1440px) {
      border-top: 5px solid rgba(254, 143, 1, 1);
      border-right: 0px;
    }
    @media only screen and (max-width: 1700px) {
      width: 370px !important;
    }
  }
  .store-date-th {
    display: flex;
    align-items: center;
    gap: 10px;
    .store-date-th-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: var(--table-cell-text);
      text-transform: uppercase;
      &__¥ {
        color: var(--color-primary);
      }
    }
  }
`;

export const TabStatusStoreStyled = styled.div`
  background-color: var(--backgroud-main);
  padding: 10px 20px;
  border-top-left-radius: 10px;
  border-bottom: 1px solid var(--store-tab-border-bottom);

  .space-between {
    justify-content: space-between;
  }
  .status_item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 10px 0;
    border-radius: 10px;
    align-items: center;
    cursor: pointer;
  }
  .status_item_active {
    background-color: var(--store-tab-active);
    //box-shadow: var(--store-search-boxshadow);
    .status_item_text {
      //color: #fff;
      color: #c020f3;
    }
    .status_item_icon svg path {
      //fill: #fff;
      fill: #c020f3;
    }
  }
  .status_item_icon {
    display: flex;
    svg {
      width: 32px;
      height: 32px;
      path {
        fill: var(--store-tab-icon);
      }
    }
  }
  .status_item_text {
    color: var(--store-tab-text);
    font-size: 14px;
    font-weight: 600;
    //line-height: 16.94px;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  .status_item_background_icon {
    width: 60px;
    height: 60px;
    background-color: var(--store-background-tab);
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    @media only screen and (max-width: 1700px) {
      width: 40px;
      height: 40px;
    }
  }
`;

export const SearchStyled = styled.div`
  flex: 1;
  padding: 15px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  background: var(--store-search-background-2);
  @media only screen and (max-width: 1440px) {
    padding: 15px 15px 0px 15px;
    border-radius: 0px !important;
  }

  .ant-input-affix-wrapper {
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    border: unset;
    background: transparent;
    padding: 0px 10px 0px 15px !important;
  }

  .ant-input-group-addon {
    background: transparent;
  }

  .search-input-prefix {
    width: auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .search-export {
    margin: 0 -15px -20px 0px;
  }
  .search-input-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    height: 60px !important;
    border: 1px solid var(--store-search-border);
    background-color: var(--backgroud-main);
    .ant-input-wrapper {
      background-color: var(--store-search-input-background);
      border-radius: 10px;
    }
  }
  .ant-input-affix-wrapper {
    &:focus,
    &:hover {
      border: unset !important;
      box-shadow: unset !important;
    }
    border: unset !important;
    box-shadow: unset !important;
  }
  .search-input-prefix-icon {
    display: flex;
    svg path {
      fill: var(--store-search-placeholder);
    }
  }
  .search-input {
    .search-input-prefix-text,
    input,
    input::placeholder {
      color: var(--store-search-placeholder);
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
    }
    input {
      text-transform: unset;
    }
    .ant-input-search-button {
      height: 40px;
      border: unset;
      background-color: transparent;
      border-radius: 0px 10px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: var(--store-search-input-icon);
        }
      }
    }
    .ant-input {
      &:focus,
      &:hover {
        outline: none;
        border-color: unset !important;
        box-shadow: unset !important;
      }
      background: transparent;
      padding-left: 10px !important;
      padding-right: 0px !important;
      height: 40px;
      border: unset;
      border-radius: 10px 0px 0px 10px;
    }
  }
`;

export const SearchDateStyled = styled.div`
  .total-fee-delivery {
    padding: 20px 10px;
    cursor: pointer;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
  .disabled-commision {
    background: #7b8190 !important;
    pointer-events: none;
  }

  .commission-fee-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
  }
  .total-fee-delivery-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    letter-spacing: 0.016em;
    color: var(--total-fee-delivery-text);
    text-align: right;
  }
  .total-fee-delivery-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  .total-fee-delivery-value {
    span {
      font-size: 20px 10px;
      font-weight: 600;
      line-height: 24.2px;
      color: var(--total-fee-delivery-value-d);
    }
    font-family: 'Inter';
    font-size: 26px;
    font-weight: 600;
    line-height: 21.78px;
    letter-spacing: 0.016em;
    color: var(--total-fee-delivery-value);
  }
  .search-status-footer {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1440px) {
      padding: 15px 10px;
    }
    .export {
      display: flex;
      svg path {
        fill: var(--search-status-footer-icon-export);
      }
    }
    .request-delivery {
      padding: 10px;
      gap: 5px;
      border-radius: 5px;
      background: var(--search-status-footer-delivery-background);
      display: flex;
      align-items: center;
      cursor: pointer;
      .delivery {
        display: flex;
        svg path {
          fill: var(--search-status-footer-delivery-text);
        }
      }
      .request-delivery-text {
        color: var(--search-status-footer-delivery-text);
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        text-transform: uppercase;
      }
    }
  }
  .search-date-commission {
    background-color: var(--store-search-date-background);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 10px 0 10px;
    border-radius: 0 0 10px 10px;
  }
  .search-date-commission-img {
    //max-height: 100px;
    width: 80px;
  }
  .align-center {
    align-items: center;
  }

  .search-date-commission-wrapper {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid var(--store-search-commission-border);
    box-shadow: var(--store-search-commission-shadow);
    display: flex;
    background-color: var(--store-search-date-commission-backgorund);
    gap: 15px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    &__active {
      border-color: var(--color-primary);
    }
  }
  .search-date-commission-paid-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .search-date-commission-cash-icon {
    margin-right: -20px;
  }
  .search-date-commission-paid {
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    color: var(--store-search-commission-paid);
    text-transform: uppercase;
    @media only screen and (max-width: 1700px) {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
    }
  }
  .search-date-commission-paid-money {
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    color: rgba(244, 106, 106, 1);
    text-transform: uppercase;
    @media only screen and (max-width: 1700px) {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
    }
  }
  .search-commission-wait-to-pay {
    color: var(--store-search-commission-wait-pay);
  }
  .search-commission-wait-to-pay-wrapper {
    position: relative;
    padding: 20px 10px 50px 10px;
    margin-top: 20px;
  }
  .search-commission-request {
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-radius: 20px 0 20px;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    padding: 5px 10px;
    gap: 10px;
  }
  .search-commission-request-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .search-commission-request-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: var(--color-active);
    text-transform: uppercase;
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
    }
  }
  .search-date-commission-view {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .search-date-commission-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;

    color: var(--store-search-date-commission-text);
    text-transform: uppercase;
  }
  .search-date-commission-value {
    font-size: 26px;
    font-weight: 600;
    //line-height: 24.2px;
    text-align: end;
    color: var(--store-search-date-commission-text);
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;

      color: var(--color-text-main);
    }
  }
  .search-date-wrapper {
    background-color: var(--store-search-date-background);
    border-radius: 10px;
    .search-date-tab {
      border-radius: 10px 10px 0px 0px;
      background-color: var(--store-search-date-header-background);
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 20px;
      .search-date-tab-item {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.36px;
        text-transform: uppercase;
        color: var(--store-search-date-tab);
        &__active {
          font-size: 16px;
          line-height: 16.94px;
        }
      }
      .search-date-select-wrapper {
        display: flex;
        align-items: center;
        background-color: var(--store-search-date-select);
        border-radius: 5px;
        padding: 10px;
        flex: 1;
        justify-content: space-between;
        cursor: pointer;
      }
      .search-date-icon {
        width: 20px;
        height: 20px;
        &__down {
          svg path {
            fill: var(--store-search-date-icon-down);
          }
        }
        &__calendar {
          svg path {
            fill: var(--store-search-date-icon-calendar);
          }
        }
      }
    }
  }

  .search-date-detail {
    padding: 15px 10px;
    display: flex;

    .search-date-detail-item-v2 {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;
      padding-right: 10px;
      &__has-border {
        border-left: 1px solid var(--search-date-border-right);
      }
      .search-date-detail-item-number {
        max-width: 90%;
      }
    }
    .max-55 {
      max-width: 55%;
    }

    .flex-1 {
      flex: 1;
    }
    .search-date-detail-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      border-radius: 20px;
      background-color: var(--backgroud-main);
      border: 1px solid var(--store-search-date-detail-border);
      box-shadow: var(--store-search-date-detail-box-shadow);
      &__active {
        border: 1px solid var(--color-primary);
        .search-date-detail-item-footer-percent {
          color: var(--color-primary);
        }
      }
      @media only screen and (max-width: 1700px) {
        gap: 8px;
        padding: 10px 4px;
      }
      .padding-1440px {
        @media only screen and (max-width: 1440px) {
          padding: 15px 10px;
        }
      }
    }
    .search-date-detail-item-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      max-width: 100%;
    }
    .search-date-detail-item-header-icon {
      &__v2 svg {
        width: 18px !important;
        height: 18px !important;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      &__box {
        svg path {
          fill: var(--store-search-date-detail-icon-box);
        }
      }
      &__weigh {
        svg path {
          fill: var(--store-search-date-detail-icon-weigh);
        }
      }
      &__box-2 {
        svg path {
          fill: var(--store-lading-box-icon);
        }
      }
      &__total-money {
        svg path {
          fill: var(--store-lading-total-money-icon);
        }
      }
    }
    .search-date-detail-item-header-text {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      @media only screen and (max-width: 1700px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
      }

      &__v2 {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        @media only screen and (max-width: 1700px) {
          font-size: 18px;
          font-weight: 600;
          line-height: 23.78px;
        }
      }
      text-transform: uppercase;
      &__bill {
        color: var(--store-search-date-detail-text-bill);
      }
      &__total {
        color: var(--store-search-date-detail-text-total);
      }
    }
    .search-date-detail-item-number {
      font-family: 'Inter';
      font-size: 24px;
      font-weight: 600;
      line-height: 29.05px;
      text-align: center;
      color: var(--color-text-main);
      @media only screen and (max-width: 1700px) {
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
      }
    }
    .search-date-detail-item-footer {
      &__v2 {
        background-color: unset;
        gap: 5px;
        padding: 0px;
      }
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 5px 0;
      justify-content: center;
      border-radius: 10px;
      background-color: var(--store-search-date-detail-footer);
    }
    .search-date-detail-item-footer-percent {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      &__up {
        color: var(--store-search-date-detail-footer-up);
      }
      &__down {
        color: var(--store-search-date-detail-footer-down);
      }
    }
  }
`;

export const SearchTotalPayStyled = styled(SearchDateStyled)`
  .search-status-select-wrapper {
    gap: 5px !important;
  }
  .search-date-wrapper .search-date-tab .search-total-pay-header {
    line-height: 26px;
  }
  .search-total-pay-wrapper {
    @media only screen and (max-width: 1440px) {
      padding-bottom: 10px;
    }
  }
  .search-date-detail-item-header,
  .search-date-tab {
    justify-content: space-between;

    .search-date-detail-item-header-text {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      color: var(--store-delivered-status-text);
      text-align: center;

      @media only screen and (max-width: 1700px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
      }
    }
  }
  .search-delivered-status-value {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    text-align: center;
    color: var(--store-delivered-status-value);

    &__received {
      color: var(--store-delivered-status-value__received);
    }
    @media only screen and (max-width: 1700px) {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
    }
  }

  .search-total-pay {
    padding: 15px 10px;
    // padding: 15px 16px;
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    box-shadow: var(--right-panel-box-shadow);
    border: var(--right-panel-border);
    background-color: var(--backgroud-main);
    margin: 5px;
    border-radius: 10px;
    color: rgba(141, 65, 238, 1);
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    @media only screen and (max-width: 1700px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      padding: 15px 16px;
    }
    @media only screen and (max-width: 1440px) {
      padding: 15px 25px;
      margin-bottom: 0;
    }
  }

  .search-total-pay-value {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    line-height: 29.05px;
    color: var(--store-delivered-total);
    @media only screen and (max-width: 1700px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
    }
  }
`;

export const ExportStyled = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 -10px -10px 0;
  .search-export-wrapper {
    border-radius: 20px 0px 20px 0px;
    background-color: var(--color-primary);
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    @media only screen and (max-width: 1700px) {
      padding: 4px 12px;
    }
  }
  .search-export-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-active);
      }
    }
  }
  .search-export-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: var(--color-active);
    text-transform: uppercase;
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
    }
  }
`;

export const SearchStatusStyled = styled(SearchDateStyled)`
  .search-status-wrapper {
    position: relative;
  }
  .search-status-filter {
    @media only screen and (max-width: 1440px) {
      padding: 15px 15px;
      .search-date-detail-item {
        padding: 10px;
      }
    }
  }
  .search-status-view-detail-text {
    font-size: 16px !important ;
    font-weight: 600;
    line-height: 19.36px !important;
    text-transform: uppercase;
    color: var(--store-search-status-view-detail);
  }
  .search-status-detail-item-footer {
    padding: 8px 0 !important;
    cursor: pointer;
  }
`;

export const SearchStatusExportStyled = styled(ExportStyled)`
  margin: 10px 0 0;
`;

export const SelectDepotStyled = styled.div`
  border-radius: 5px;
  overflow: auto;
  background-color: var(--depot-background);
  box-shadow: var(--depot-box-shadow);
  border: var(--depot-border-container);

  .select-employee-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
    color: var(--employee-header);
    padding: 15px;
    border-bottom: 0.5px solid var(--depot-border);
  }

  .select-depot-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background-color: var(--depot-background);
    border-bottom: 0.5px solid var(--depot-border);
    &:last-child {
      border: unset;
    }
  }

  .select-depot-logo {
    width: 24px;
    height: 16px;
    border-radius: 4px;
    svg path {
      fill: var(--depot-color);
    }
  }
  .select-depot-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: var(--depot-color);
    text-transform: uppercase;
  }
  .select-depot-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: transparent;
      }
    }
    &__active {
      svg path {
        stroke: var(--depot-icon);
      }
    }
  }
`;

export const SearchBillStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;

  .search-bill-logo {
    width: 132px;
    height: 60px;
    object-fit: contain;
    align-self: center;
  }
  .search-bill-detail-item {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .search-bill-detail-dot {
    background-color: var(--search-bill-details-item-dot);
    min-width: 7px;
    height: 7px;
    border-radius: 50%;
  }
  .search-bill-detail-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 0px;
    border-bottom: 1px solid var(--search-bill-details-item-border);
  }

  .search-bill-status {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-transform: uppercase;
    color: var(--search-bill-status);
    &__name {
      color: var(--search-bill-status-name);
    }
    &__phone {
      color: var(--search-bill-status-number);
    }
  }
  .search-bill-detail-main-1 {
    display: flex;
    justify-content: space-between;
  }
  .search-bill-detail-content {
    flex: 1;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: var(--color-text-main);
  }
  .search-bill-detail-time {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: var(--search-bill-details-time);
  }

  .search-bill-header {
    padding: 20px;
    padding-top: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid var(--dropdown-lading-border);
  }
  .search-bill-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;

    text-align: center;
    color: var(--search-bill-text);

    &__active {
      font-weight: 700;
      line-height: 19.36px;
    }
  }
  .search-icon-copy {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .search-header-close {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    background-color: rgba(254, 143, 1, 1);
    border-radius: 10px 0px 10px 0px;
    height: 30px;

    @media only screen and (max-width: 1440px) {
      border-radius: 0px 0px 10px 0px;
    }

    padding: 1px 12px;
    display: flex;
    svg {
      width: 24px;
      height: 24px;
      rect {
        fill: transparent;
      }
      path {
        stroke: var(--search-bill-close-icon);
      }
    }
  }

  .search-bill-footer {
    background-color: var(--search-bill-footer);
    text-align: center;
    padding: 15px;
    color: var(--search-bill-footer-text);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 0px 0px 0px 10px;
    @media only screen and (max-width: 1440px) {
      border-radius: 0;
    }
  }
  .search-bill-content {
    flex: 1;
    background-color: var(--search-bill-content);
    overflow: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .search-bill-empty {
    padding: 30px 20px 70px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .search-bill-no-data {
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    text-align: center;
    color: var(--search-bill-no-data);
  }

  .search-bill-suggest {
    background-color: var(--search-bill-suggest-wrapper);
    box-shadow: var(--search-bill-suggest-wrapper-shadow);
    padding: 30px 10px 10px;
    position: relative;
    border-radius: 20px;
  }
  .search-bill-suggest-box {
    background-color: var(--search-bill-suggest-box);
    color: var(--search-bill-suggest);
    border-radius: 20px 0 20px 0;
    padding: 3px 30px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
  }
  .search-bill-suggest-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    margin: 10px 0px;
    color: var(--search-bill-suggest-text);
    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      color: var(--search-bill-suggest-text__active);
    }
  }
  .search-bill-reason-return {
    background-color: var(--search-bill-reason-return);
    box-shadow: var(--search-bill-reason-return-shadow);
    padding: 50px 30px;
    position: relative;
    border-radius: 20px;
  }
  .search-bill-return-box {
    background-color: var(--search-bill-reason-return-box);
    color: var(--search-bill-suggest);
    border-radius: 20px 0 20px 0;
    padding: 3px 30px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
  }
  .search-bill-reason-return-view {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .search-bill-reason-return-text {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: var(--search-bill-suggest-text);
  }
  .search-bill-reason-return-copy-icon {
    width: 20px;
    height: 20px;
  }
  .search-bill-reason-return-copy {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: var(--search-bill-reason-copy);
  }
`;

export const TableLandingStyled = styled.div`
  height: 100%;

  .ant-form-item {
    margin-bottom: 0px;
  }
  span.ant-input-status-error.ant-input:hover,
  span.ant-input-status-error.ant-input {
    border-color: unset; // var(--input-error-border);
  }
  .ant-form-item-explain {
    display: none;
  }
  .ant-input {
    border: unset !important; // 1px solid var(--login-input-border);
    font-size: 18px;
    font-weight: 500;
    line-height: 19.36px;
    height: 40px;
    background-color: transparent;
    color: var(--color-text-input-table-lading);
    padding: 4px 0px !important;

    border-radius: 4px;
    &:hover {
      box-shadow: unset;
      //border-color: unset; // var(--login-input-border__active);
    }
    &:focus {
      color: var(--color-text-input-table-lading-focus);
      box-shadow: unset;
      border: 0.2px solid var(--login-input-border__active) !important;
      box-sizing: border-box;
    }
    @media only screen and (max-width: 1920px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
    }
  }

  .ant-input-number {
    border: unset !important;
    background-color: transparent;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input {
    font-size: 18px;
    font-weight: 500;
    line-height: 19.36px;
    height: 40px;
    color: var(--color-text-main);
    padding: 4px 0px !important;

    @media only screen and (max-width: 1920px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
    }
  }
  .ant-input-focused,
  .ant-input-number-focused {
    box-shadow: unset;
    border-color: unset; // var(--login-input-border__active);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
  }
  .input-link-product .ant-input {
    color: var(--store-lading-input-link);
  }

  .input-count {
    .ant-input-number-input-wrap {
      position: relative;
    }
    input {
      color: var(--color-primary);
    }
    .ant-input-number input:focus {
      border: 0.5px solid rgb(91, 115, 232) !important;
    }
  }

  .input-price {
    .ant-input-number-input-wrap {
      position: relative;
    }
    input {
      color: var(--color-primary);
    }
    .ant-input-number-outlined:focus-within {
      border: 0.5px solid rgb(91, 115, 232) !important;
      box-shadow: unset !important;
    }
    .ant-input-number-affix-wrapper {
      background: none;
      border: 1px solid transparent;
      padding: 0px !important;
      /* &:hover, */
      &:focus {
        border: 0.5px solid rgb(91, 115, 232) !important;
      }
      .ant-input-number-prefix {
        color: var(--table-footer-display) !important;
        margin-top: 3px;
      }
      .ant-input-number-affix-wrapper input:focus {
        border: 0.5px solid rgb(91, 115, 232) !important;
      }
    }
  }
  .shipFee {
    .ant-input-number-input-wrap {
      position: relative;
      padding: 0px !important;
    }
    .ant-input-number-prefix {
      color: var(--table-footer-display) !important;
      margin-top: 3px;
    }

    .ant-input-number-outlined:focus-within {
      border: 0.5px solid rgb(91, 115, 232) !important;
      box-shadow: unset !important;
    }
    .ant-input-number-affix-wrapper:focus-within {
      border: 0.5px solid rgb(91, 115, 232) !important;
    }
    .ant-input-number-affix-wrapper {
      background: none;
      border: none;
      /* padding: 0px !important; */
      /* &:hover, */
      &:focus {
        border: 0.5px solid rgb(91, 115, 232) !important;
      }
    }
  }
  .ant-form-item-control-input-content {
    justify-content: center;
    display: flex;
  }
  .ant-table-tbody .ant-table-cell {
    padding: 6px 10px !important;
    @media only screen and (max-width: 1700px) {
      padding: 6px 10px !important;
    }
  }
`;

export const ModalInsertStyled = styled.div`
  background-color: var(--modal-insert-background);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 20px 20px;
  .modal-header {
    background-color: var(--modal-insert-header);
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    margin: 0px -20px;
    text-align: center;
    color: var(--color-active);
    position: relative;
    text-transform: uppercase;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0%);
      bottom: -10px;
      width: 0px;
      height: 0px;
      border-top: solid 10px var(--modal-insert-header);
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
    }
    &__delete {
      background-color: var(--color-primary);
      &::before {
        border-top-color: var(--color-primary);
      }
    }
  }
  .button-icon-close {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    right: 10px;
    svg {
      rect {
        fill: transparent;
      }
      path {
        stroke: var(--color-active);
      }
    }
  }

  .modal-insert-note {
    background-color: var(--modal-insert-note-background);
    color: var(--modal-insert-note-text);
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    padding: 10px 0px;
    border-radius: 10px;
    text-transform: uppercase;
    span {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      color: var(--color-primary);
    }
  }
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button-cancel {
    border: 1px solid var(--color-primary);
    color: var(--modal-insert-button-insert);
    background-color: transparent;
    border-radius: 10px;
    width: 180px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    &:active,
    &:hover {
      border-color: var(--color-primary) !important;
      color: var(--modal-insert-button-insert) !important;
    }
  }

  .button-add,
  .button-add:hover {
    background-color: var(--modal-insert-button-insert) !important;
    color: var(--color-active);
    border: unset;
    border-radius: 10px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    &:active,
    &:hover {
      border-color: unset !important;
      color: var(--color-active) !important;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
  span.ant-input-status-error.ant-input:hover,
  span.ant-input-status-error.ant-input {
    border-color: var(--input-error-border);
  }
  .ant-form-item-explain {
    display: none;
  }
  .ant-input {
    border: 1px solid var(--login-input-border);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    height: 120px;
    background-color: transparent;
    color: var(--login-input-placeholder);
    border-radius: 10px;
    &::placeholder {
      color: var(--login-input-placeholder);
      text-transform: uppercase;
    }
    &:hover,
    &:focus {
      box-shadow: unset;
      border-color: var(--login-input-border__active);
    }
    resize: none;
  }
  .ant-input-number-input {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    height: 40px;
    color: var(--login-input-placeholder);
  }
  .ant-input-focused {
    box-shadow: unset;
    border-color: var(--login-input-border__active);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner,
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary);
  }
`;

export const ModalDeleteStyled = styled(ModalInsertStyled)`
  padding-bottom: 60px;
  background-color: var(--modal-delete-background);
  .modal-delete-warning-text {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 27px;
    text-align: center;
    color: var(--modal-delete-text);
  }

  .modal-delete-warning {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 15px;
    background-color: var(--modal-delete-background-warning);
    border-radius: 10px;
  }

  .modal-delete-warning-icon {
    display: flex;
  }

  .modal-delete-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 20px 0px 20px 0px;
    background-color: var(--color-primary);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-transform: uppercase;

    cursor: pointer;
    color: var(--color-active);
  }
`;

export const LadingStatusStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .menu {
    padding: 15px;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .dropdown-lading-item {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 5px;
    gap: 10px;
    border: 1px solid var(--dropdown-lading-border);
    background: var(--dropdown-lading-background);
    cursor: pointer;
  }
  .dropdown-lading-name {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    //line-height: 16.94px;
    text-align: left;
    text-transform: uppercase;
    color: var(--dropdown-lading-color);
    @media only screen and (max-width: 1400px) {
      font-size: 14px;
    }
  }

  .h-56 {
    height: 56px;
  }
  .gap-15 {
    gap: 15px;
    display: flex;
    flex-direction: column;
  }
  .lading-detail {
    padding: 15px 10px !important;
    justify-content: space-between;
  }
  .store-lading-text {
    color: var(--store-lading-text);
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    &__total-money {
      color: var(--store-lading-total-money-text);
    }
  }
  .store-lading-number {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    color: var(--store-lading-number);
    &__total-money {
      color: var(--store-lading-total-money-number);
    }
  }
  .search-date-icon__between svg path {
    fill: var(--color-text-main) !important;
  }
  .lading-status-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .lading-status-header-icon {
    display: flex;
    svg {
      width: 40px;
      height: 40px;
      path {
        fill: var(--store-lading-status-header);
      }
    }
  }

  .lading-status-header-text {
    color: var(--store-lading-status-header);
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;
    letter-spacing: 0.016em;
    text-align: center;
    text-transform: uppercase;
  }

  .lading-status-add-new {
    cursor: pointer;
    background-color: var(--color-primary);
    margin: 0px -17px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0 0 20px 20px;
    text-transform: uppercase;
    box-shadow: var(--right-panel-box-shadow);
    border: var(--right-panel-border);
  }

  .lading-status-add-new-icon {
    display: flex;
    svg {
      width: 32px;
      height: 32px;
    }
  }

  .lading-status-add-new-text {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.63px;
    color: var(--color-active);
  }

  .lading-status-item {
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--store-lading-status-item);
    border: var(--right-panel-border);
    &__active {
      border: 1px solid var(--color-primary);
      box-shadow: var(--right-panel-box-shadow);
    }
    @media only screen and (max-width: 1700px) {
      padding: 10px 5px;
    }
  }

  .lading-status-item-header {
    display: flex;
    align-items: center;
    gap: 10px;

    &__not_sign_yet {
      svg path {
        fill: var(--store-lading-status-not-sign-yet);
      }
      .lading-status-item-header-text {
        color: var(--store-lading-status-not-sign-yet);
      }
    }
    &__box {
      .lading-status-item-header-text {
        color: var(--store-lading-status-signed);
      }
    }
    &__out_of_store {
      svg path {
        stroke: var(--store-lading-status-out-store);
      }
      .lading-status-item-header-text {
        color: var(--store-lading-status-out-store);
      }
    }
    &__checking {
      svg path {
        fill: var(--store-lading-status-checking);
      }
      .lading-status-item-header-text {
        color: var(--store-lading-status-checking);
      }
    }
    &__come_to_vn {
      svg path {
        stroke: var(--store-lading-status-come-to-vn);
      }
      .lading-status-item-header-text {
        color: var(--store-lading-status-come-to-vn);
      }
    }
    &__delivery {
      .lading-status-item-header-text {
        color: var(--store-lading-status-delivered);
      }
    }
  }
  .lading-status-item-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .lading-status-item-header-text {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: left;
    @media only screen and (max-width: 1700px) {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
    }
  }
  .lading-status-item-header-value {
    font-size: 26px;
    font-weight: 600;
    line-height: 31.47px;
    text-align: center;
    padding: 10px 0px;
    color: var(--color-text-main);
    @media only screen and (max-width: 1700px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      padding: 0;
    }
  }
`;

export const WithDrawCommissionStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--with-draw-background);
  border-radius: 10px 0 0 10px;
  @media only screen and (max-width: 1440px) {
    border-radius: 10px 0 0 0px;
  }
  padding-bottom: 50px;
  .with-draw-form-input {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 15px;
    .button-confirm {
      background-color: rgba(253, 116, 1, 1);
      color: #fff;
      padding: 17px;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .with-draw-select {
    background-color: var(--with-draw-second-background);
    padding: 20px 15px;
    input,
    .ant-input-prefix {
      height: 37px;
    }
  }
  .ant-input-affix-wrapper {
    padding: 0px !important;
    overflow: hidden;
    border-radius: 5px;
  }

  input,
  .ant-input-prefix {
    height: 47px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: var(--account-info-form-input);
    cursor: pointer;
    text-transform: uppercase;
    &::placeholder {
      color: var(--with-draw-prefix-input-placeholder);
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      text-transform: uppercase;
    }
  }
  .ant-input-prefix {
    padding: 10px;
    background-color: var(--with-draw-prefix-input);
    border-right: 0px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  span.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper:hover,
  span.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper {
    border-color: var(--input-error-border);
  }
  .ant-form-item-explain {
    display: none;
  }

  .ant-input-affix-wrapper {
    border: var(--account-info-form-border) !important;
    box-shadow: unset !important;

    &:hover,
    &:focus {
      box-shadow: unset;
      border: var(--account-info-form-border) !important;
    }
  }

  input,
  .ant-input-affix-wrapper {
    background-color: var(--account-input-background);
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--account-info-form-input);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
  }

  .account-input-suffix {
    display: flex;
    svg {
    }
  }

  .with-draw-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      display: flex;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      color: var(--with-draw-header);
    }
    .more {
      display: flex;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  .with-draw-second {
    background-color: var(--with-draw-second-background);
    padding: 15px 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: var(--with-draw-second-text);
    text-transform: uppercase;
  }

  .with-draw-bank {
    padding: 20px;
    position: relative;
    height: 248px;
    .img-background {
      width: calc(100% - 40px);
      height: 208px;
      object-fit: cover;
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 15px;
    }
    .header {
      z-index: 2;
      position: relative;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .bank-name {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
        text-align: left;
        color: var(--with-draw-main-text);
        text-transform: uppercase;
      }
      .show {
        color: var(--with-draw-show);
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-transform: uppercase;
      }
      .eye {
        display: flex;
        svg {
          width: 17px;
          height: 12px;
        }
      }
    }

    .bank-number {
      z-index: 2;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .value {
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        font-weight: 600;
        line-height: 26.82px;
        color: var(--with-draw-main-text);
        text-transform: uppercase;
      }
    }
    .three {
      z-index: 2;
      position: relative;
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .bank-acount-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 21.78px;
        text-align: right;
        color: var(--with-draw-main-text);
        text-transform: uppercase;
      }
      .edit {
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.52px;
        text-align: right;
        color: var(--with-draw-main-text);
        text-transform: uppercase;
        opacity: 0.8;
      }
    }

    .with-draw-footer {
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: flex-end;
      right: 20px;
      bottom: 20px;
      .button {
        border-radius: 15px 0 15px 0;
        padding: 12px 30px;
        background-color: var(--with-draw-button);
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        text-align: left;
        color: var(--with-draw-main-text);
        text-transform: uppercase;
      }
    }
  }
`;

export const HeaderCommission = styled.div`
  border-radius: 10px 0 0 0;
  background-color: var(--commission-header);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    border-radius: 0 !important;
  }
  .close {
    display: flex;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .hiden {
    visibility: hidden;
  }
  .header {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.78px;
    text-align: left;
    text-transform: uppercase;
    color: var(--commission-text);
  }
`;
