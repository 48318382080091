import { Button, Modal, Row, Space, Divider, Form, Col, Input, Select, Upload, Avatar } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { TitlePopover } from 'components/dropdown/Login';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userActions } from 'redux/userInfo';
import { FooterStyled, Styled } from './styled';
import InputLayout from 'components/common/InputLayout';
import { validationRules } from 'utils/validate';
import { getBase64, normFile } from 'libs/utils';
import { Text } from 'components/common/Text';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

const AvatarImage = memo(({ file }) => {
  const [base64, setBase64] = useState();

  useEffect(() => {
    if (!!file) getBase64File();
  }, [file]);

  const getBase64File = async () => {
    try {
      const base64 = await getBase64(file);
      setBase64(base64);
    } catch (error) {}
  };
  return <Avatar src={base64} className="avatar" size={120} icon={<UserOutlined />} />;
});
const ModalUpdateUser = () => {
  const list = useAppSelector((state) => state.country.list);
  const openModalUpdate = useAppSelector((state) => state.user.openUpdate);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.user.userInfo);

  useEffect(() => {
    if (openModalUpdate) {
      form.setFieldsValue(userInfo);
    }
  }, [form, openModalUpdate, userInfo]);

  const onCloseModal = useCallback(() => {
    dispatch(userActions.onCloseModalUpdate());
  }, []);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
    } catch (e) {
      console.log(e);
    }
  };

  if (!openModalUpdate) return null;
  const Footer = () => (
    <FooterStyled>
      <Divider style={{ marginBottom: 20 }} />
      <Row className="justify-content-end align-items-center">
        <Space>
          <Button type="primary" ghost onClick={onCloseModal}>
            {t('common:cancel').toUpperCase()}
          </Button>
          <Button type="primary" htmlType="submit" onClick={onFinish}>
            {t('common:save').toUpperCase()}
          </Button>
        </Space>
      </Row>
    </FooterStyled>
  );

  return (
    <Modal
      width={800}
      onCancel={onCloseModal}
      footer={<Footer />}
      // open={openModalUpdate}
      title={null}
      closable={false}
    >
      <Styled gender={t('common:gender').toUpperCase()} country={t('common:country').toUpperCase()}>
        <TitlePopover title={t('common:update-user').toUpperCase()} onClose={onCloseModal} />
        <Form form={form} onFinish={onFinish}>
          <InputLayout>
            <Row gutter={[20, 10]}>
              <Col md={8} xs={24}>
                <div className="avatar-info">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.username !== currentValues.username
                    }
                  >
                    {({ getFieldValue }) => (
                      <Text className="text-center fw-600 fs-16">{getFieldValue('username')}</Text>
                    )}
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.files !== currentValues.files
                    }
                  >
                    {({ getFieldValue }) => {
                      return (
                        <Form.Item
                          name="files"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                        >
                          <Upload
                            maxCount={1}
                            fileList={[]}
                            accept="image/*"
                            className="center"
                            multiple={false}
                            itemRender={null}
                          >
                            <AvatarImage file={getFieldValue('files')?.[0]?.originFileObj} />
                          </Upload>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Text className="text-center fw-500">
                    {t('common:member-from')}:{' '}
                    {!!userInfo.createdDate
                      ? moment(userInfo.createdDate).format('DD-MM-YYYY')
                      : ''}
                  </Text>
                </div>
              </Col>
              <Col md={16} xs={24}>
                <Row gutter={[15, 0]}>
                  <Col xs={24}>
                    <Form.Item name="username" rules={[validationRules.required()]}>
                      <Input
                        addonBefore={t('common:fullname').toUpperCase()}
                        autoFocus
                        placeholder={t('common:fullname').toUpperCase()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item name="phone" rules={[validationRules.required()]}>
                      <Input
                        placeholder={t('common:phone').toUpperCase()}
                        addonBefore={t('common:phone').toUpperCase()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item name="email" rules={[validationRules.required()]}>
                      <Input
                        placeholder={t('MAIL').toUpperCase()}
                        addonBefore={t('MAIL').toUpperCase()}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="gender" rules={[validationRules.required()]}>
                      <Select
                        data-content={t('common:gender').toUpperCase()}
                        className="select-gender"
                        placeholder={t('common:gender').toUpperCase()}
                        options={[]}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="nationId" rules={[validationRules.required()]}>
                      <Select
                        data-content={t('common:country').toUpperCase()}
                        className="select-country"
                        placeholder={t('common:country').toUpperCase()}
                        options={list?.map((i) => ({
                          label: i?.nationName,
                          value: i?.nationId,
                        }))}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <Form.Item name="address" rules={[validationRules.required()]}>
                      <Input
                        placeholder={t('common:address').toUpperCase()}
                        addonBefore={t('common:address').toUpperCase()}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </InputLayout>
        </Form>
      </Styled>
    </Modal>
  );
};

export default memo(ModalUpdateUser);
