import { t } from 'i18next';
import { isNumber } from 'lodash';
import moment from 'moment';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import {
  excelImportWareHouse,
  getListDepot,
  getListImportWareHouse,
  getStatistic,
  searchBillDetail,
} from 'services/importWareHouseService';

function* handleGetListImportWareHouse(action) {
  try {
    const [res, resStatistic] = yield all([
      call(getListImportWareHouse, action.payload),
      action.payload?.pageIndex === 1 && call(getStatistic, action.payload),
    ]);
    if (!res) yield put(importWareHouseActions.getListImportWareHouseFailed());
    if (resStatistic) yield put(importWareHouseActions.getStatistic(resStatistic?.data));
    const data = { ...res?.data };
    if (isNumber(resStatistic?.data?.Total)) data.Total = resStatistic?.data?.Total;
    yield put(importWareHouseActions.getListImportWareHouseSuccess(data));
  } catch (error) {
    yield put(importWareHouseActions.getListImportWareHouseFailed());
  }
}

function* handleGetListDepot(action) {
  try {
    const res = yield call(getListDepot, action.payload);
    if (!res) return yield put(importWareHouseActions.getListDepotFailed());
    yield put(importWareHouseActions.getListDepotSuccess(res.data));
  } catch (error) {
    yield put(importWareHouseActions.getListDepotFailed());
  }
}

function* handleGetBillDetail(action) {
  try {
    const res = yield call(searchBillDetail, action.payload);
    if (!res) return yield put(importWareHouseActions.getBillDetailFailed());
    yield put(importWareHouseActions.getBillDetailSuccess(res.data));
  } catch (error) {
    yield put(importWareHouseActions.getBillDetailFailed());
  }
}

function* exportImportWareHouse(action) {
  try {
    // const { params } = action.payload;
    const res = yield call(excelImportWareHouse, action.payload);
    if (!res) return yield put(importWareHouseActions.exportImportWareHouseFailed());
    const href = URL.createObjectURL(res);
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute(
      'download',
      `${t('store:import-ware-house')}_${moment().format('DD/MM/YYYY HH:MM')}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    yield put(importWareHouseActions.exportImportWareHouseSuccess());
  } catch (error) {
    yield put(importWareHouseActions.exportImportWareHouseFailed());
  }
}

export default function* importWareHouseSaga() {
  yield all([
    takeLatest(importWareHouseActions.getListImportWareHouse.type, handleGetListImportWareHouse),
  ]);
  yield all([takeLatest(importWareHouseActions.getListDepot.type, handleGetListDepot)]);
  yield all([takeLatest(importWareHouseActions.exportImportWareHouse.type, exportImportWareHouse)]);
  yield all([takeLatest(importWareHouseActions.getBillDetail.type, handleGetBillDetail)]);
}
