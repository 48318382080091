import Services from './index';

const getListDepot = async () => await Services.get('/api/SearchDepot/getListDepot');
const getListImportWareHouse = async (body) =>
  await Services.post('/api/SearchDepot/tranhapkho', body);
const excelImportWareHouse = async (body) =>
  await Services.post('/api/SearchDepot/ExcelNhapKho', body, {
    responseType: 'blob',
  });
const searchBillDetail = async (params) =>
  await Services.get('/api/SearchDepot/travandon', { params });
const getStatistic = async (body) =>
  await Services.post('/api/SearchDepot/tranhapkho_thongke', body);

export {
  getListDepot,
  getListImportWareHouse,
  excelImportWareHouse,
  searchBillDetail,
  getStatistic,
};
