import { USER_INFO } from 'constants/localStorage';
import { setStorage } from 'libs/storage';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from 'redux/authSlice';
import { commonActions } from 'redux/commonSlice';
import { userActions } from 'redux/userInfo';
import { getUserInfo, changePassword, insertUpdateUser, updateBankInfo } from 'services/userService';
import { toast } from 'react-toastify';
import { t } from 'i18next';

function* handleGetUserInfo(action) {
  try {
    const res = yield call(getUserInfo, action.payload);
    if (!res) return yield put(userActions.getUserInfoFailed());
    yield setStorage(USER_INFO, res.data);
    yield put(userActions.getUserInfoSuccess(res.data));
    yield put(commonActions.onCloseLogin());
    yield put(authActions.loginSuccess());
  } catch (error) {
    yield put(userActions.getUserInfoFailed());
  }
}

function* handleInsertUserInfo(action) {
  try {
    const res = yield call(insertUpdateUser, action.payload);
    if (!res) return yield put(userActions.insertUserInfoFailed());
    yield put(userActions.getUserInfo());
    toast.success(t('common:success'));
  } catch (error) {
    yield put(userActions.insertUserInfoFailed());
  }
}

function* handleUpdateBankInfo(action) {
  try {
    const res = yield call(updateBankInfo, action.payload);
    if (!res) return yield put(userActions.updateBankInfoFailed());
    yield put(userActions.getUserInfo());
    toast.success(t('common:success'));
  } catch (error) {
    yield put(userActions.updateBankInfoFailed());
  }
}

function* handleChangePassword(action) {
  try {
    const res = yield call(changePassword, action.payload);
    if (!res) return yield put(userActions.changePasswordFailed());
    yield put(userActions.changePasswordSuccess());
  } catch (error) {
    yield put(userActions.changePasswordFailed());
  }
}

export default function* userSaga() {
  yield all([takeLatest(userActions.insertUserInfo.type, handleInsertUserInfo)]);
  yield all([takeLatest(userActions.getUserInfo.type, handleGetUserInfo)]);
  yield all([takeLatest(userActions.changePassword.type, handleChangePassword)]);
  yield all([takeLatest(userActions.updateBankInfo.type, handleUpdateBankInfo)]);

}
