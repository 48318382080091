import { Button, Col, Form, Input, Row, Spin } from 'antd';
import SvgIcon from 'components/common/SvgIcon';
import { Text, TextPrimary } from 'components/common/Text';
import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import InputLayout from 'components/common/InputLayout';
import { validationRules } from 'utils/validate';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { authActions } from 'redux/authSlice';
import { commonActions } from 'redux/commonSlice';
const LoginStyled = styled.div`
  padding-top: 15px;
  padding: 5px;
  width: 470px;
  .forgot {
    text-align: end;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
`;

const FindAccountStyled = styled.div`
  width: 470px;
`;

const CreateNewPassStyled = styled.div`
  width: 470px;
`;

let timeout;

const FindAccount = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [resendingOTP, setResendingOTP] = useState(false);
  const { emailForgotPass: email, loading } = useAppSelector((state) => state.auth);

  const onCloseLogin = useCallback(() => {
    dispatch(commonActions.onCloseLogin());
  }, []);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      if (!email) return dispatch(authActions.forgotPassword(values));
      dispatch(authActions.createPassByOTP({ ...values, email }));
    } catch (e) {
      console.log(e);
    }
  };

  const onRetry = useCallback(() => {
    clearTimeout(timeout);
    setResendingOTP(true);
    timeout = setTimeout(() => {
      setResendingOTP(false);
    }, 30000);
    dispatch(authActions.resendOtpEmail({ email }));
  }, [email]);
  return (
    <FindAccountStyled>
      <Spin spinning={loading}>
        <TitlePopover title={t('common:find-account').toUpperCase()} onClose={onCloseLogin} />
        <Text className="text-center">
          {!!email
            ? t('common:please-change-pass').toUpperCase()
            : t('common:find-account-description').toUpperCase()}
        </Text>
        <TextPrimary className="text-center mb-15">{email?.toUpperCase()}</TextPrimary>
        <InputLayout>
          {!email ? (
            <Form form={form} onFinish={onFinish}>
              <Form.Item name="email" rules={[validationRules.required()]}>
                <Input
                  autoFocus
                  prefix={<SvgIcon name="email" />}
                  placeholder={'MAIL'.toUpperCase()}
                />
              </Form.Item>
            </Form>
          ) : (
            <Form form={form} onFinish={onFinish}>
              <Form.Item name="otp" rules={[validationRules.required()]}>
                <Input
                  autoFocus
                  prefix={<SvgIcon name="lock" />}
                  suffix={
                    <Button
                      onClick={onRetry}
                      disabled={resendingOTP}
                      type="link"
                      className="pointer"
                    >
                      {t('common:retry').toUpperCase()}
                    </Button>
                  }
                  placeholder={t('common:security-code').toUpperCase()}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[
                  validationRules.required(),
                  validationRules.minLength(6, t('common:minLength', { length: 6 })),
                ]}
              >
                <Input
                  autoFocus
                  prefix={<SvgIcon name="password" />}
                  placeholder={t('common:password').toUpperCase()}
                />
              </Form.Item>
              <Form.Item
                name="confirm-password"
                dependencies={['newPassword']}
                rules={[
                  validationRules.required(),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('common:confirm-password-incorrect')));
                    },
                  }),
                ]}
              >
                <Input
                  prefix={<SvgIcon name="password" />}
                  placeholder={t('common:confirm-password').toUpperCase()}
                />
              </Form.Item>
            </Form>
          )}
        </InputLayout>
        <Button type="primary" className="button-primary" htmlType="submit" onClick={onFinish}>
          {!!email ? t('common:confirm').toUpperCase() : t('common:next').toUpperCase()}
        </Button>
      </Spin>
    </FindAccountStyled>
  );
};

const CreateNewPass = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const onCloseLogin = useCallback(() => {
    dispatch(commonActions.onCloseLogin());
  }, []);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <CreateNewPassStyled>
      <TitlePopover title={t('common:new-password').toUpperCase()} onClose={onCloseLogin} />
      <Text className="text-center mb-15">
        {t('common:new-password-description').toUpperCase()}
      </Text>
      <Form form={form} onFinish={onFinish}>
        <InputLayout>
          <Form.Item
            name="password"
            rules={[
              validationRules.required(),
              validationRules.minLength(6, t('common:minLength', { length: 6 })),
            ]}
          >
            <Input
              autoFocus
              prefix={<SvgIcon name="password" />}
              placeholder={t('common:password').toUpperCase()}
            />
          </Form.Item>
          <Form.Item
            name="confirm-password"
            dependencies={['password']}
            rules={[
              validationRules.required(),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('common:confirm-password-incorrect')));
                },
              }),
            ]}
          >
            <Input
              prefix={<SvgIcon name="password" />}
              placeholder={t('common:confirm-password').toUpperCase()}
            />
          </Form.Item>
        </InputLayout>
        <Button type="primary" className="button-primary" htmlType="submit" onClick={onFinish}>
          {t('common:confirm').toUpperCase()}
        </Button>
      </Form>
    </CreateNewPassStyled>
  );
};

const Login = () => {
  const [form] = Form.useForm();
  const [step, setStep] = useState(0); //1: forgot, 2: verify, 3: new password
  const { t } = useTranslation();
  const openLogin = useAppSelector((state) => state.common.openPopoverLogin);
  const dispatch = useAppDispatch();
  const isLogging = useAppSelector((state) => state.auth.logging);

  useEffect(() => {
    if (openLogin) {
      setStep(0);
      form.resetFields();
    }
    return () => {
      dispatch(authActions.clearEmailForgotPass());
    };
  }, [openLogin]);

  const onCloseLogin = useCallback(() => {
    dispatch(commonActions.onCloseLogin());
  }, []);

  const onOpenRegister = () => {
    onCloseLogin();
    dispatch(commonActions.onOpenRegister());
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      dispatch(authActions.login(values));
    } catch (e) {
      console.log(e);
    }
  };

  if (step === 1) return <FindAccount setStep={setStep} />;
  if (step === 2) return <CreateNewPass setStep={setStep} />;

  return (
    <LoginStyled>
      <Spin spinning={isLogging}>
        <TitlePopover title={t('common:login').toUpperCase()} onClose={onCloseLogin} />

        <Form form={form} onFinish={onFinish}>
          <InputLayout>
            <Form.Item name="phone" rules={[validationRules.required()]}>
              <Input
                autoFocus
                disabled={isLogging}
                prefix={<SvgIcon name="phone" />}
                placeholder={t('common:phone').toUpperCase()}
              />
            </Form.Item>
            <Form.Item name="password" rules={[validationRules.required()]}>
              <Input.Password
                disabled={isLogging}
                prefix={<SvgIcon name="password" />}
                placeholder={t('common:password').toUpperCase()}
              />
            </Form.Item>
          </InputLayout>
          <TextPrimary className="forgot" onClick={() => setStep(1)}>
            {t('common:forgot').toUpperCase()}
          </TextPrimary>
          <Button
            type="primary"
            className="button-primary"
            htmlType="submit"
            onClick={onFinish}
            disabled={isLogging}
          >
            {t('common:login').toUpperCase()}
          </Button>
          <div className="text-center">
            <span>
              <Text className="d-inline-block">{t('common:no-account').toUpperCase()}</Text>
            </span>{' '}
            <span>
              <TextPrimary className="d-inline-block fw-500 pointer" onClick={onOpenRegister}>
                {t('common:register-now').toUpperCase()}
              </TextPrimary>
            </span>
          </div>
        </Form>
      </Spin>
    </LoginStyled>
  );
};

export default Login;

const Styled = styled(Row)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.border};
  margin-bottom: 25px;
`;

export const TitlePopover = memo(({ title, onClose }) => (
  <Styled gutter={[20, 20]} style={{ alignItems: 'center', padding: '4px 8px' }}>
    <Col style={{ visibility: 'hidden' }}>
      <SvgIcon name="closeDropdown" />
    </Col>
    <Col flex="auto">
      <Text className="text-center fs-20 fw-600">{title}</Text>
    </Col>
    <Col onClick={onClose} className="pointer">
      <SvgIcon name="closeDropdown" />
    </Col>
  </Styled>
));
