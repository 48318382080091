import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  list: [],
  listCity: [],
  listDistrict: [],
  listWard: [],
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    getCountry(state) {
      state.loading = true;
    },
    getCountrySuccess(state, action) {
      state.loading = false;
      state.list = action.payload;
    },
    getCountryFailed(state) {
      state.loading = false;
    },

    getCitis(state) {
      state.loading = true;
    },
    getCitisSuccess(state, action) {
      state.loading = false;
      state.listCity = action.payload;
    },
    getCitisFailed(state) {
      state.loading = false;
    },
    getDistricts(state) {
      state.loading = true;
    },
    getDistrictsSuccess(state, action) {
      state.loading = false;
      state.listDistrict = action.payload;
    },
    getDistrictsFailed(state) {
      state.loading = false;
    },
    getWards(state) {
      state.loading = true;
    },
    getWardsSuccess(state, action) {
      state.loading = false;
      state.listWard = action.payload;
    },
    getWardsFailed(state) {
      state.loading = false;
    },
  },
});

// Actions
export const countryActions = countrySlice.actions;

// Reducer
const countryReducer = countrySlice.reducer;
export default countryReducer;
