import { Col, Row } from 'antd';
import classNames from 'classnames';
import SvgIcon from 'components/common/SvgIcon';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import { TabStatusStoreStyled, HeaderCommission } from '../styled';
import { DASHBOARD_ROUTE } from 'routes';
import { useNavigate } from 'react-router-dom';

export const TAB_ID_STATUS_STORE = {
  WAREHOUSE: 'WAREHOUSE',
  DELIVERED: 'DELIVERED',
  LANDING: 'LANDING',
  COMMISSION: 'COMMISSION',
};

const Item = memo(({ image = '', text = '', active = false, onClick = () => {} }) => (
  <Col span={8}>
    <div onClick={onClick} className={classNames('status_item', { status_item_active: active })}>
      <SvgIcon name={image} className="status_item_icon" />
      <div className="status_item_text text-ellipsis">{text}</div>
    </div>
  </Col>
));

const TabStatusStore = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDark = useSelector((state) => state.common.darkmode);
  const filter = useSelector((state) => state.importWareHouse.filter);
  const { statusStore } = filter;
  const onChangeStatusStore = (id) =>
    id !== statusStore && dispatch(importWareHouseActions.changeStatusStore(id));

  const LIST_ACTION = useMemo(() => {
    return [
      {
        image: 'tab_warehouse',
        text: t('store:import-ware-house'),
        id: [TAB_ID_STATUS_STORE.WAREHOUSE, TAB_ID_STATUS_STORE.COMMISSION],
      },
      {
        image: 'tab_delivered',
        text: t('store:delivered'),
        id: [TAB_ID_STATUS_STORE.DELIVERED],
      },
      {
        image: 'tab_lading',
        text: t('store:bill-of-lading-management'),
        id: [TAB_ID_STATUS_STORE.LANDING],
      },
    ];
  }, [isDark]);

  return statusStore === TAB_ID_STATUS_STORE.COMMISSION ? (
    <HeaderCommission>
      <SvgIcon name="red_x" className="close" onClick={() => navigate(DASHBOARD_ROUTE)} />
      <div className="header">{t('store:my-commission')}</div>
      <SvgIcon name="red_x" className="close hiden" />
    </HeaderCommission>
  ) : (
    <TabStatusStoreStyled>
      <Row className="space-between">
        {LIST_ACTION?.map((item, index) => (
          <Item
            key={`${item?.id}_${index}`}
            {...item}
            active={item.id?.includes(statusStore)}
            onClick={() => onChangeStatusStore(item?.id?.[0])}
          />
        ))}
      </Row>
    </TabStatusStoreStyled>
  );
};

export default memo(TabStatusStore);
