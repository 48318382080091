import { createSlice } from '@reduxjs/toolkit';
import { DARK_MODE } from 'constants/localStorage';
import { deleteStorage, setStorage } from 'libs/storage';
import moment from 'moment';

const initialState = {
  darkmode: false,
  openPopoverLogin: false,
  openPopoverRegister: false,
  routerBeforeLogin: '',
  rangeCalendar: {
    date: moment(),
    startDate: moment(),
    endDate: moment(),
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    changeDarkMode(state, action) {
      state.darkmode = action.payload.darkmode;
      action.payload.darkmode ? setStorage(DARK_MODE, true) : deleteStorage(DARK_MODE);
    },
    onOpenLogin(state) {
      state.openPopoverLogin = true;
    },
    onCloseLogin(state) {
      state.openPopoverLogin = false;
    },
    saveRouterBeforeLogin(state, action) {
      state.routerBeforeLogin = action.payload;
    },
    onOpenRegister(state) {
      state.openPopoverRegister = true;
    },
    onCloseRegister(state) {
      state.openPopoverRegister = false;
    },
    onChangeRangeCalendar(state, actions) {
      state.rangeCalendar = { ...state.rangeCalendar, ...actions?.payload };
    },
    onResetRangeCalendar(state) {
      state.rangeCalendar = { ...initialState.rangeCalendar };
    },
  },
});

// Actions
export const commonActions = commonSlice.actions;

// Reducer
const commonReducer = commonSlice.reducer;
export default commonReducer;
