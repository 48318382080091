import { memo } from 'react';
import { styled } from 'styled-components';

const Styled = styled.div`
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-search-button {
    /* background-color: ${({ theme }) => theme.backgroundInput}; */
  }
  .ant-input-search-button {
    svg {
      width: 20px;
      margin-top: 5px;
      height: 20px;
    }
  }
  .ant-input-affix-wrapper:hover {
    box-shadow: 0px 2px 10px 0px;
    color: ${({ theme }) => theme.boxShadowInput};
  }
`;
const InputLayout = ({ children }) => {
  return <Styled>{children}</Styled>;
};

export default memo(InputLayout);
