import { css } from 'styled-components';

const Styled = css`
  * {
    /* transition: all 1s linear; */

    &::-webkit-scrollbar {
      width: auto;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--table-scrollbar);
      background-color: transparent;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .popover-qr-social {
    .ant-popover-inner {
      border-radius: 20px;
      box-shadow: unset;
    }
    .ant-popover-inner-content {
      border-radius: 20px;
      padding: 15px 7px 9px;
      background: rgba(172, 189, 222, 0.7);
    }
  }
  /* th {
    transition: all 1s linear !important;
  } */
  .dropdown-select-item {
    width: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background-color: transparent; // var(--depot-background);
    border-bottom: 1px solid var(--depot-border);
    &:last-child {
      border: unset;
    }
  }
  .dropdown-select-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: var(--store-search-placeholder);
    text-transform: uppercase;
  }

  .dropdown-select-icon {
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: transparent;
      }
    }
  }

  .ant-dropdown-menu-item-selected {
    background-color: transparent !important;
    .dropdown-select-icon {
      svg path {
        stroke: var(--depot-icon);
      }
    }
  }
  .select-options-dropdown {
    border-radius: 5px;
    background-color: var(--depot-background);
    box-shadow: var(--depot-box-shadow);
    border: var(--depot-border-container);

    /* border: 1px solid var(--depot-border); */
    .ant-dropdown-menu {
      background-color: transparent;
      padding: 0;
      border-radius: 5px !important;
    }
    .ant-dropdown-menu-item {
      border-bottom: 1px solid var(--depot-border);
      border-radius: 0px !important;
      padding: 0 !important;
      &:last-child {
        border-bottom: unset;
      }
      &:hover {
        background-color: unset !important;
      }
    }
    .ant-dropdown-arrow:before {
      background-color: var(--background-arrow-popover);
    }
  }

  .user-setting-dropdown {
    top: 70px !important;
  }
  .modal-custom .ant-modal-content {
    padding: 0px;
    background-color: transparent;
    border-radius: 20px;
  }

  .dropdown-select-icon-zoom {
    svg path {
      fill: var(--color-text-main);
    }
  }
  .modal-transparent {
    .ant-spin-container {
      display: flex;
      justify-content: center;
    }
    .ant-modal-content {
      background-color: transparent;
      box-shadow: unset;
      padding: 0;
    }
  }
  .note-tooltip {
    .tooltip-content-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
    }
    .tooltip-d1 {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      letter-spacing: 0.01em;
      text-align: left;
      color: var(--tooltip-d1);
    }
    .line {
      min-width: 1px;
      height: 19px;
      background-color: var(--tooltip-d1);
    }
    .ant-tooltip-arrow:before {
      background: var(--tooltip-note-bg);
    }

    .tooltip-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .ant-tooltip-inner {
      border-radius: 10px;
      padding: 10px 20px;
      background-color: var(--tooltip-note-bg);
      border: 1px solid var(--tooltip-note-border);
      box-shadow: var(--tooltip-note-box-shadow);
    }
    .tooltip-header {
      display: flex;
      align-items: center;
      gap: 15px;
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--tooltip-note-icon);
        }
      }
    }
    .tooltip-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;

      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: var(--tooltip-note-icon);
    }
    .tooltip-content {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      letter-spacing: 0.01em;

      text-transform: uppercase;
      color: var(--tooltip-note-text);
    }
  }

  .ant-menu-submenu-popup .ant-menu-sub .ant-menu-item {
    &:last-child {
      display: none;
    }
  }
`;
export default Styled;
