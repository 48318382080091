import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_zh from 'languages/zh/common.json';
import common_vi from 'languages/vi/common.json';
import dashboard_zh from 'languages/zh/dashboard.json';
import dashboard_vi from 'languages/vi/dashboard.json';
import store_zh from 'languages/zh/store.json';
import store_vi from 'languages/vi/store.json';
import user_zh from 'languages/zh/user.json';
import user_vi from 'languages/vi/user.json';
import home_zh from 'languages/zh/home.json';
import home_vi from 'languages/vi/home.json';
// the translations
// (tip move them in a JSON file and import them,
// or evzh better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  zh: {
    common: common_zh,
    dashboard: dashboard_zh,
    store: store_zh,
    user: user_zh,
    home: home_zh
  },
  vi: {
    common: common_vi,
    dashboard: dashboard_vi,
    store: store_vi,
    user: user_vi,
    home: home_vi
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'vi',
    ns: ['common', 'dashboard'],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
