import { t } from 'i18next';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { importWareHouseActions } from 'redux/importWareHouseSlice';
import { landingActions } from 'redux/landingSlice';
import {
  getListLanding,
  insertUpdate,
  getLadingHistory,
  getSampleExcel,
  importLading,
  createMany,
  deletes,
  getStatus,
} from 'services/landingService';

function* handleGetList(action) {
  try {
    const res = yield call(getListLanding, action.payload);
    if (!res) return yield put(landingActions.getListLandingFailed());
    const data = res?.data;
    yield put(landingActions.getListLandingSuccess(data));
  } catch (error) {
    yield put(landingActions.getListLandingFailed());
  }
}

function* handleInsertUpdate(action) {
  try {
    const res = yield call(insertUpdate, action.payload);
    if (!res) return yield put(landingActions.insertUpdateLandingFailed());
    action?.payload?.handleSuccess(res);
    yield put(landingActions.insertUpdateLandingSuccess(res?.data));
  } catch (error) {
    yield put(landingActions.insertUpdateLandingFailed());
  }
}

function* handleGetHistory(action) {
  try {
    const res = yield call(getLadingHistory, action.payload);
    if (!res) return yield put(landingActions.getLandingHistoryFailed());
    yield put(landingActions.getLandingHistorySuccess(res?.data));
  } catch (error) {
    yield put(landingActions.getLandingHistoryFailed());
  }
}

function* handleGetTemplate(action) {
  try {
    const res = yield call(getSampleExcel, action.payload);
    if (res) {
      const href = URL.createObjectURL(res);
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${t('store:lading-template')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  } catch (error) {}
}

function* handleUpload(action) {
  try {
    const res = yield call(importLading, action.payload);
    if (!res) return yield put(landingActions.uploadLandingExcelFailed());
    yield put(importWareHouseActions.onChangeFilter({ pageIndex: 1 }));
    toast.success(t('common:success'));
  } catch (error) {
    yield put(landingActions.uploadLandingExcelFailed());
  }
}

function* handleCreateMany(action) {
  try {
    const res = yield call(createMany, action.payload);
    if (!res) return yield put(landingActions.insertMultiLandingFailed());
    yield put(landingActions.insertMultiLandingSuccess());
    yield put(importWareHouseActions.onChangeFilter({ pageIndex: 1 }));
  } catch (error) {
    yield put(landingActions.insertMultiLandingFailed());
  }
}

function* handleDeletes(action) {
  try {
    const res = yield call(deletes, action.payload);
    if (!res) return yield put(landingActions.deletesFailed());
    yield put(landingActions.deletesSuccess());
    yield put(importWareHouseActions.onChangeFilter({ pageIndex: 1 }));
  } catch (error) {
    yield put(landingActions.deletesFailed());
  }
}

function* handleGetStatus(action) {
  try {
    const res = yield call(getStatus, action.payload);
    if (!res) return;
    yield put(landingActions.getStatusSuccess(res?.data));
  } catch (error) {}
}

export default function* commissionSaga() {
  yield all([takeLatest(landingActions.getListLanding.type, handleGetList)]);
  yield all([takeLatest(landingActions.insertUpdateLanding.type, handleInsertUpdate)]);
  yield all([takeLatest(landingActions.getLandingHistory.type, handleGetHistory)]);
  yield all([takeLatest(landingActions.downloadTemplate.type, handleGetTemplate)]);
  yield all([takeLatest(landingActions.uploadLandingExcel.type, handleUpload)]);
  yield all([takeLatest(landingActions.insertMultiLanding.type, handleCreateMany)]);
  yield all([takeLatest(landingActions.deletes.type, handleDeletes)]);
  yield all([takeLatest(landingActions.getStatus.type, handleGetStatus)]);
}
