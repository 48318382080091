import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  registerPolicy: undefined,
};

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    getRegisterPolicy(state) {
      state.loading = true;
    },
    getRegisterPolicySuccess(state, action) {
      state.loading = false;
      state.registerPolicy = action.payload;
    },
    getRegisterPolicyFailed(state) {
      state.loading = false;
    },
    clearRegisterPolicy(state) {
      state.registerPolicy = undefined;
    },
  },
});

// Actions
export const articleActions = articleSlice.actions;

// Reducer
const articleReducer = articleSlice.reducer;
export default articleReducer;
