import axios from 'axios';
import { ACCESS_TOKEN, LANGUAGE } from 'constants/localStorage';
import { getStorage } from 'libs/storage';
import { trimData } from 'libs/utils';
import { toast } from 'react-toastify';
import { store } from 'app/store';
import { authActions } from 'redux/authSlice';
import { commonActions } from 'redux/commonSlice';
import moment from 'moment';

let errorHandle = null;
async function parseBody(response) {
  let resData = response?.data;
  return resData;
}

/**
 * axios instance
 */
// let baseURL = ''
let instance = axios.create({
  baseURL: window.env.REACT_APP_BASE_URL,
  timeout: 40000,
});

// request header
instance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    if (config.data) {
      config.data = config.data instanceof FormData ? config.data : trimData(config.data);
    }
    config.headers = {
      Authorization: getStorage(ACCESS_TOKEN),
      Lang: getStorage(LANGUAGE) || 'vi',
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response parse
instance.interceptors.response.use(parseBody, async (error) => {
  if (error.response.status === 401) {
    const { dispatch } = store; // direct access to redux store.
    dispatch(authActions.logout());
    dispatch(
      commonActions.changeDarkMode(
        !moment().isBetween(moment('07:00', 'HH:mm'), moment('19:00', 'HH:mm'))
      )
    );
  }
  errorHandle = errorHandle ? errorHandle : handleError(error.response?.data?.message);
  if (errorHandle) await errorHandle;
  errorHandle = null;
  return Promise.reject(error);
});

const handleError = async (msg) =>
  new Promise((resolve) => {
    setTimeout(() => {
      toast.error(msg);
      resolve();
    }, 100);
  });

export default instance;

export const httpGetFile = (path = '', optionalHeader = {}) => {
  return instance({
    method: 'GET',
    url: path,
    headers: { ...optionalHeader },
  });
};
