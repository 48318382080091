import { all, call, put, takeLatest } from 'redux-saga/effects';
import { articleActions } from 'redux/articleSlice';
import { getRegisterPolicy } from 'services/articleService';

function* getRequestPolicy(action) {
  try {
    const res = yield call(getRegisterPolicy, action.payload);
    if (!res) return yield put(articleActions.getRegisterPolicyFailed());
    yield put(articleActions.getRegisterPolicySuccess(res?.data?.ListArticle));
  } catch (error) {
    yield put(articleActions.getRegisterPolicyFailed());
  }
}

export default function* userSaga() {
  yield all([takeLatest(articleActions.getRegisterPolicy.type, getRequestPolicy)]);
}
