import i18next from 'i18next';

export const validationRules = {
  required: (message = i18next.t('common:required')) => ({
    required: true,
    message,
  }),
  minLength: (length = 2, message = i18next.t('common:minLength', { length: 2 })) => ({
    min: length,
    message,
  }),
  maxLength: (message, length = 10) => ({
    max: length,
    message: message || `Enter characters at most ${length}`,
  }),
  email: (message) => ({
    type: 'email',
    message: message || 'EMAIL KHÔNG HỢP LỆ',
  }),
};
