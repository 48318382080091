import Services from './index';

const login = async (body) => await Services.post('/api/Account/signin', body);
const register = async (body) => await Services.post('/api/Account/register', body);
const confirmEmailOtp = async (body) => await Services.post('/api/Account/confirmEmailOTP', body);
const resendOtpEmail = async (body) => await Services.post('/api/Account/resendOTPEmail', body);
const forgotPassword = async (body) => await Services.post('/api/Account/forgotPassword', body);
const createPasswordByOTP = async (body) =>
  await Services.post('/api/Account/reCreatePasswordByOtp', body);
const checkValidOTP = async (body) => await Services.post('/api/Account/checkValidOTP', body);

export {
  login,
  register,
  confirmEmailOtp,
  resendOtpEmail,
  forgotPassword,
  createPasswordByOTP,
  checkValidOTP,
};
