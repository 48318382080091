import { memo } from 'react';
import { styled } from 'styled-components';

const TextStyle = styled.div`
  color: ${({ theme }) => theme.textColor};
`;

const TextPrimaryStyle = styled.div`
  color: ${({ theme }) => theme.primaryText};
`;

export const Text = memo(({ className, children, style }) => {
  return (
    <TextStyle className={className} style={style}>
      {children}
    </TextStyle>
  );
});

export const TextPrimary = memo(({ className, children, style, onClick }) => {
  return (
    <TextPrimaryStyle className={className} style={style} onClick={onClick}>
      {children}
    </TextPrimaryStyle>
  );
});

export const TextElipsis = memo(({ text, isMoney, ...props }) => {
  return (
    <>
      {isMoney ? (
        <>
          <span class="color-money" style={{ marginRight: 2 }}>
            ¥
          </span>
          <span className="text-ellipsis" title={text} {...props} style={{ fontWeight: 500 }}>
            {text}
          </span>
        </>
      ) : (
        <div className="text-ellipsis" title={text} {...props}>
          {text}
        </div>
      )}
    </>
  );
});
