import { all, call, put, takeLatest } from 'redux-saga/effects';
import { countryActions } from 'redux/countrySlice';
import { getListCity, getListCountry, getListDistrict, getListWard } from 'services/countryService';

function* handleGetListCountry(action) {
  try {
    const res = yield call(getListCountry, action.payload);
    if (!res) return yield put(countryActions.getCountryFailed());
    yield put(countryActions.getCountrySuccess(res.data));
  } catch (error) {
    yield put(countryActions.getCountryFailed());
  }
}

function* handleGetListCity(action) {
  try {
    const res = yield call(getListCity, action.payload);
    if (!res) return yield put(countryActions.getCitisFailed());
    yield put(countryActions.getCitisSuccess(res.data));
  } catch (error) {
    yield put(countryActions.getCitisFailed());
  }
}
function* handleGetListDistrict(action) {
  try {
    const res = yield call(getListDistrict, action.payload);
    if (!res) return yield put(countryActions.getDistrictsFailed());
    yield put(countryActions.getDistrictsSuccess(res.data));
  } catch (error) {
    yield put(countryActions.getDistrictsFailed());
  }
}
function* handleGetListWard(action) {
  try {
    const res = yield call(getListWard, action.payload);
    if (!res) return yield put(countryActions.getWardsFailed());
    yield put(countryActions.getWardsSuccess(res.data));
  } catch (error) {
    yield put(countryActions.getWardsFailed());
  }
}

export default function* countrySaga() {
  yield all([takeLatest(countryActions.getCountry.type, handleGetListCountry)]);
  yield all([takeLatest(countryActions.getCitis.type, handleGetListCity)]);
  yield all([takeLatest(countryActions.getWards.type, handleGetListWard)]);
  yield all([takeLatest(countryActions.getDistricts.type, handleGetListDistrict)]);
}
