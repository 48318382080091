import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import i18next from 'i18next';
const initialState = {
  loading: false,
  userInfo: {},
  openUpdate: false,
  openChangePassword: false,
  openSetting: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onChangOpenUserSetting(state, action) {
      state.openSetting = action.payload;
    },
    insertUserInfo(state) {
      state.loading = true;
    },
    insertUserInfoFailed(state) {
      state.loading = false;
    },
    updateBankInfo(state) {
      state.loading = true;
    },
    updateBankInfoFailed(state) {
      state.loading = false;
    },
    getUserInfo(state) {
      state.loading = true;
    },
    getUserInfoSuccess(state, action) {
      state.loading = false;
      state.userInfo = action.payload;
    },
    getUserInfoFailed(state) {
      state.loading = false;
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload;
    },
    deleteUserInfo(state) {
      state.userInfo = {};
    },
    onOpenModalUpdate(state) {
      state.openUpdate = true;
    },
    onCloseModalUpdate(state) {
      state.openUpdate = false;
    },
    changePassword(state) {
      state.loading = true;
    },
    changePasswordSuccess(state, action) {
      state.loading = false;
      state.openChangePassword = false;
      toast.success(i18next.t('common:change-pass-success'));
    },
    changePasswordFailed(state) {
      state.loading = false;
    },
    openChangePassword(state) {
      state.openChangePassword = true;
    },
    closeChangePassword(state) {
      state.openChangePassword = false;
    },
  },
});

// Actions
export const userActions = userSlice.actions;

// Reducer
const userReducer = userSlice.reducer;
export default userReducer;
