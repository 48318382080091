import React from 'react';

import Loading from 'components/common/Loading';
import { useRoutes } from 'react-router-dom';
import {
  ADMIN_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  SVG_ROUTER,
  COMMISSION_ROUTER,
} from 'routes';
import './App.css';

const Login = React.lazy(() => import('pages/login'));
const Home = React.lazy(() => import('pages/home'));
const Admin = React.lazy(() => import('pages/admin'));
const Store = React.lazy(() => import('pages/store'));
const SvgPage = React.lazy(() => import('pages/svg-icon'));

const NotFound = React.lazy(() => import('components/common/NotFound'));
const PrivateRoute = React.lazy(() => import('components/layouts/private'));
const PublicRoute = React.lazy(() => import('components/layouts/public'));

const LazyLoadingComponent = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="center" style={{ height: '100vh', width: '100vw' }}>
          <Loading />
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
};

const routes = [
  {
    path: LOGIN_ROUTE,
    element: (
      <LazyLoadingComponent>
        <Login />
      </LazyLoadingComponent>
    ),
  },
  {
    path: SVG_ROUTER,
    element: (
      <LazyLoadingComponent>
        <SvgPage />
      </LazyLoadingComponent>
    ),
  },
  {
    path: '*',
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoute />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ADMIN_ROUTE,
        element: (
          <LazyLoadingComponent>
            <Admin />
          </LazyLoadingComponent>
        ),
      },
      {
        path: DASHBOARD_ROUTE,
        element: (
          <LazyLoadingComponent>
            <Store />
          </LazyLoadingComponent>
        ),
      },
      {
        path: COMMISSION_ROUTER,
        element: (
          <LazyLoadingComponent>
            <Store commission />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    element: (
      <LazyLoadingComponent>
        <PublicRoute />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: HOME_ROUTE,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
];
function App() {
  const element = useRoutes(routes);
  return element;
}

export default App;
