import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  loadingExport: false,
  dataDelivered: {},
  statistic: {},
  balance: {},
};

const deliveredSlice = createSlice({
  name: 'delivered',
  initialState,
  reducers: {
    getListDelivered(state) {
      state.loading = true;
    },
    getListDeliveredSuccess(state, action) {
      state.loading = false;
      state.dataDelivered = { ...state.dataDelivered, ...action.payload };
    },
    getListDeliveredFailed(state) {
      state.loading = false;
    },
    exportDelivered(state) {
      state.loadingExport = true;
    },
    exportDeliveredSuccess(state, action) {
      state.loadingExport = false;
    },
    exportdeliveredFailed(state) {
      state.loadingExport = false;
    },
    getStatistic(state, action) {
      state.statistic = { ...state.statistic, ...action.payload };
    },
    getBalance(state, action) {
      state.balance = action.payload;
    },
    clearDelivered(state) {
      state.dataDelivered = {};
    },
    clearData: () => initialState,
  },
});

// Actions
export const deliveredActions = deliveredSlice.actions;

// Reducer
const deliveredReducer = deliveredSlice.reducer;
export default deliveredReducer;
