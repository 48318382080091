import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  listEmployee: [],
  data: {},
};

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {
    getListDiscount(state) {
      state.loading = true;
    },
    getListDiscountSuccess(state, action) {
      state.loading = false;
      if (action.payload?.pageIndex === 1) state.data = action.payload;
      else {
        const newList = {
          ...action.payload,
          listData: (state?.data?.listData || [])?.concat(action.payload?.listData),
        };
        state.data = newList;
      }
    },
    getListDiscountFailed(state) {
      state.loading = false;
    },
    getListEmployee(state) {
      state.loading = true;
    },
    getListEmployeeSuccess(state, action) {
      state.listEmployee = action.payload;
    },
    getListEmployeeFailed(state) {
      state.loading = false;
    },

    exportDiscount(state) {
      state.loading = true;
    },
    exportDiscountSuccess(state, action) {
      state.loading = false;
    },
    exportDiscountFailed(state) {
      state.loading = false;
    },
    clearEmployee(state) {
      state.listEmployee = [];
    },
    clearDiscount(state) {
      state.data = {};
    },
  },
});

// Actions
export const discountActions = discountSlice.actions;

// Reducer
const discountReducer = discountSlice.reducer;
export default discountReducer;
