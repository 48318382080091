import Services from './index';

const getListDelivered = async (body) => await Services.post('/api/SearchDepot/trahangdatra', body);
const excelDelivered = async (body) =>
  await Services.post('/api/SearchDepot/ExcelDaTra', body, {
    responseType: 'blob',
  });
const getStatistic = async (body) =>
  await Services.post('/api/SearchDepot/trahangdatra_thongke', body);

export { excelDelivered, getListDelivered, getStatistic };
