import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import authReducer, { authActions } from 'redux/authSlice';
import commonReducer from 'redux/commonSlice';
import userReducer from 'redux/userInfo';
import countryReducer from 'redux/countrySlice';
import importWareHouseReducer from 'redux/importWareHouseSlice';
import deliveredReducer from 'redux/deliveredSlice';
import discountReducer from 'redux/discountSlice';
import articleReducer from 'redux/articleSlice';
import commissionReducer from 'redux/commissionSlice';
import landingReducer from 'redux/landingSlice';

import rootSaga from './rootSaga';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  user: userReducer,
  country: countryReducer,
  importWareHouse: importWareHouseReducer,
  delivered: deliveredReducer,
  discount: discountReducer,
  article: articleReducer,
  commission: commissionReducer,
  landing: landingReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      ignoredActions: [authActions.register.type],
      serializableCheck: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
