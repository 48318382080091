export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const themeCustom = {
  [THEME.DARK]: {
    textColor: '#fff',
    textDarkColor: '#000',
    backgroundColor: '#000',
    primaryText: '#5B73E8',
    slideColor: '#fff',
    borderPrimaryDark: '#5B73E8',
    border: '#373737',
    backgroundInput: '#494949',
    boxShadowInput: 'transparent',
    backgroundIcon: '#333333',
    iconFill: '#fff',
    backgroundContent: '#18191A',
    backgroundTable: '#000',
    boxShadowContent: 'rgba(52, 55, 71, 0.15)',
    borderSegete: '#494949',
    headerTable: 'rgba(255, 255, 255, 0.15)',
    rowHover: '#5B73E8',
    backgroundSegmented: '#141414',
    footer: '#474747',
    selectAddonBackground: '#1f1f1f',
    selectAddonColor: 'rgba(255, 255, 255, 0.85)',
    selectAddonBorder: '#424242',
  },
  [THEME.LIGHT]: {
    textColor: '#000',
    textDarkColor: '#000',
    backgroundColor: '#fff',
    primaryText: '#5B73E8',
    slideColor: '#F6F6F6',
    borderPrimaryDark: '#DDDDDD',
    border: '#ECEFF1',
    backgroundInput: '#fff',
    boxShadowInput: 'rgba(189, 199, 246, 1)',
    backgroundIcon: '#ECEFF1',
    backgroundContent: '#F5F6F8',
    backgroundTable: '#fff',
    boxShadowContent: 'rgba(52, 55, 71, 0.15)',
    borderSegete: '#DDD',
    headerTable: 'rgba(236, 239, 241, 0.70)',
    rowHover: '#dee6fd',
    backgroundSegmented: '#fff',
    footer: '#F6F8FE',
    selectAddonBackground: 'rgba(0, 0, 0, 0.02)',
    selectAddonColor: 'rgba(0, 0, 0, 0.88)',
    selectAddonBorder: '#d9d9d9',
  },
};
