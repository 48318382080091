import { ACCESS_TOKEN, USER_INFO } from 'constants/localStorage';
import { deleteStorage, setStorage } from 'libs/storage';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { authActions } from 'redux/authSlice';
import { commonActions } from 'redux/commonSlice';
import { userActions } from 'redux/userInfo';
import {
  confirmEmailOtp,
  createPasswordByOTP,
  forgotPassword,
  login,
  register,
  resendOtpEmail,
  checkValidOTP,
} from 'services/authService';

function* handleLogin(action) {
  try {
    const res = yield call(login, action.payload);
    if (!res) return yield put(authActions.loginFailed());
    yield setStorage(ACCESS_TOKEN, res?.data?.token);
    yield put(userActions.getUserInfo());
  } catch (error) {
    yield put(authActions.loginFailed());
  }
}

function* handleRegister(action) {
  try {
    const res = yield call(register, { ...action.payload });
    if (!res) return yield put(authActions.registerFailed());
    yield put(authActions.registerSuccess(action.payload.email));
  } catch (error) {
    yield put(authActions.registerFailed());
  }
}

function* handleConfirmEmailOtp(action) {
  try {
    const res = yield call(confirmEmailOtp, action.payload);
    if (!res) return yield put(authActions.confirmEmailOtpFailed());
    yield put(authActions.confirmEmailOtpSuccess(action.payload.otp));
  } catch (error) {
    yield put(authActions.confirmEmailOtpFailed());
  }
}
function* handleCheckValidOTP(action) {
  try {
    const res = yield call(checkValidOTP, action.payload);
    if (!res) return yield put(authActions.confirmEmailOtpFailed());
    yield put(authActions.confirmEmailOtpSuccess(action.payload.otp));
  } catch (error) {
    yield put(authActions.confirmEmailOtpFailed());
  }
}
function* handleResendOtpEmail(action) {
  try {
    const res = yield call(resendOtpEmail, action.payload);
    if (!res) return yield put(authActions.resendOtpEmailFailed());
    yield put(authActions.resendOtpEmailSuccess());
  } catch (error) {
    yield put(authActions.resendOtpEmailFailed());
  }
}

function* handleForgotPassword(action) {
  try {
    const res = yield call(forgotPassword, { ...action.payload });
    if (!res) return yield put(authActions.forgotPasswordFailed());
    yield put(authActions.forgotPasswordSuccess(action.payload.email));
  } catch (error) {
    yield put(authActions.forgotPasswordFailed());
  }
}

function* handleCreatePassByOTP(action) {
  try {
    const res = yield call(createPasswordByOTP, action.payload);
    if (!res) return yield put(authActions.createPassByOTPFailed());
    yield put(authActions.createPassByOTPSuccess());
    yield put(commonActions.onCloseLogin());
  } catch (error) {
    yield put(authActions.createPassByOTPFailed());
  }
}

function* handleLogout() {
  yield deleteStorage(ACCESS_TOKEN);
  yield deleteStorage(USER_INFO);
  yield put(userActions.deleteUserInfo());
}

export default function* authSaga() {
  yield all([takeLatest(authActions.login.type, handleLogin)]);
  yield all([takeLatest(authActions.register.type, handleRegister)]);
  yield all([takeLatest(authActions.checkValidOTP.type, handleCheckValidOTP)]);
  yield all([takeLatest(authActions.confirmEmailOtp.type, handleConfirmEmailOtp)]);
  yield all([takeLatest(authActions.resendOtpEmail.type, handleResendOtpEmail)]);
  yield all([takeLatest(authActions.forgotPassword.type, handleForgotPassword)]);
  yield all([takeLatest(authActions.createPassByOTP.type, handleCreatePassByOTP)]);
  yield all([takeLatest(authActions.logout.type, handleLogout)]);
}
