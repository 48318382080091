import Services from './index';

const getListLanding = async (body) => await Services.post('/api/LadingManage/filter', body);

const insertUpdate = async (body) => await Services.post('/api/LadingManage/createupdate', body);
const getLadingHistory = async (params) =>
  await Services.get('/api/LadingManage/ladingHistory', { params });
const getSampleExcel = async () =>
  await Services.post(
    '/api/LadingManage/getSampleExcel',
    {},
    {
      responseType: 'blob',
    }
  );
const importLading = async (body) => await Services.post('/api/LadingManage/import', body);
const deletes = async (body) => await Services.post('/api/LadingManage/Deletes', body);
const createMany = async (body) => await Services.post('/api/LadingManage/createmany', body);
const getStatus = async (params) => await Services.get('/api/LadingManage/getStatus', { params });

export {
  getListLanding,
  insertUpdate,
  getLadingHistory,
  getSampleExcel,
  importLading,
  deletes,
  createMany,
  getStatus,
};
