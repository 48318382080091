import styled from 'styled-components';

export const FooterStyled = styled.div`
  margin: 0px -10px;
`;

export const Styled = styled.div`
  .avatar {
    border: 3px solid #f8f9fa;
    background-color: ${({ theme }) => theme.primaryText};
    svg {
      width: 60px;
      height: 60px;
    }
  }
  .avatar-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 24px;
    border: 1px solid;
    height: calc(100% - 18px);
    border-color: ${({ theme }) => theme.borderSegete};
    border-radius: 8px;
  }
  .ant-upload-list-text {
    display: none;
  }

  .select-country:before {
    content: attr(data-content);
    position: absolute;
    z-index: 1000;
    top: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 11px;
    color: ${({ theme }) => theme.selectAddonColor};
    font-weight: normal;
    font-size: 14px;
    background-color: ${({ theme }) => theme.selectAddonBackground};
    border-right: 1px solid;
    border-right-color: ${({ theme }) => theme.selectAddonBorder};
  }
  .select-country {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding-left: 94px !important;
    }
  }
  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.selectAddonBackground} !important;
  }
  .select-gender:before {
    content: attr(data-content);
    position: absolute;
    z-index: 1000;
    top: 1px;
    bottom: 1px;
    left: 1px;
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 11px;
    color: ${({ theme }) => theme.selectAddonColor};
    font-weight: normal;
    font-size: 14px;
    background-color: ${({ theme }) => theme.selectAddonBackground};
    border-right: 1px solid;
    border-right-color: ${({ theme }) => theme.selectAddonBorder};
  }
  .select-gender {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding-left: 74px !important;
    }
  }
`;
