import { createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  loadingHistory: false,
  dataLanding: {},
  idInsert: '',
  landingHistory: [],
  deteleItems: [],
  deleting: false,
  openInsert: false,
  inserting: false,
  ladingStatus: [],
};

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    onChangeOpenInsert(state, action) {
      state.openInsert = action?.payload;
    },
    onChangeDeteleItems(state, action) {
      state.deteleItems = action.payload;
    },
    getListLanding(state) {
      state.loading = true;
    },
    getListLandingSuccess(state, action) {
      state.loading = false;
      state.dataLanding = { ...state.dataLanding, ...action.payload };
    },
    getListLandingFailed(state) {
      state.loading = false;
    },
    getLandingHistory(state) {
      state.loadingHistory = true;
    },
    getLandingHistorySuccess(state, action) {
      state.landingHistory = action.payload;
      state.loadingHistory = false;
    },
    getLandingHistoryFailed(state) {
      state.loadingHistory = false;
    },
    deletes(state) {
      state.deleting = true;
    },
    deletesSuccess(state) {
      state.deleting = false;
      state.deteleItems = [];
      toast.success(t('common:success'));
    },
    deletesFailed(state) {
      state.deleting = false;
    },
    insertMultiLanding(state) {
      state.inserting = true;
    },
    insertMultiLandingSuccess(state) {
      state.inserting = false;
      state.openInsert = false;
      toast.success(t('common:success'));
    },
    insertMultiLandingFailed(state) {
      state.inserting = false;
    },
    insertUpdateLanding() { },
    insertUpdateLandingSuccess(state, action) {
      state.idInsert = action.payload;
    },
    insertUpdateLandingFailed() { },
    uploadLandingExcel(state) {
      state.loading = true;
    },
    uploadLandingExcelSuccess(state) {
      state.loading = false;
    },
    uploadLandingExcelFailed(state) {
      state.loading = false;
    },
    getStatus() { },
    getStatusSuccess(state, action) {
      state.ladingStatus = action.payload;
    },
    downloadTemplate() { },
    clearHistory(state) {
      state.landingHistory = [];
    },
    clearData: () => initialState,
  },
});

// Actions
export const landingActions = landingSlice.actions;

// Reducer
const landingReducer = landingSlice.reducer;
export default landingReducer;
