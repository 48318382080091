import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { toast } from 'react-toastify';

const initialState = {
  loading: false,
  dataCommission: {},
  listEmployee: [],
};

const commissionSlice = createSlice({
  name: 'commission',
  initialState,
  reducers: {
    getListCommission(state) {
      state.loading = true;
    },
    getListCommissionSuccess(state, action) {
      state.loading = false;
      state.dataCommission = { ...state.dataCommission, ...action.payload };
    },
    getListCommissionFailed(state) {
      state.loading = false;
    },
    getListEmployee(state) {
      state.loading = true;
    },
    getListEmployeeSuccess(state, action) {
      state.loading = false;
      state.listEmployee = action.payload;
    },
    getListEmployeeFailed(state) {
      state.loading = false;
    },
    withDrawMoney(state) {
      state.loading = true;
    },
    withDrawMoneySuccess(state) {
      state.loading = false;
      toast.success(i18next.t('common:success'));
    },
    withDrawMoneyFailed(state) {
      state.loading = false;
    },
    getStatistic(state, action) {
      state.statistic = action.payload;
    },
    clearData: () => initialState,
  },
});

// Actions
export const commissionActions = commissionSlice.actions;

// Reducer
const commissionReducer = commissionSlice.reducer;
export default commissionReducer;
