import { all, call, put, takeLatest } from 'redux-saga/effects';
import { discountActions } from 'redux/discountSlice';
import { getListDiscount, getListEmployee } from 'services/discountService';

function* handleGetListDiscount(action) {
  try {
    const res = yield call(getListDiscount, action.payload);
    if (!res) return yield put(discountActions.getListDiscountFailed());
    yield put(
      discountActions.getListDiscountSuccess({
        ...res.data,
        pageIndex: action?.payload?.pageIndex,
      })
    );
  } catch (error) {
    yield put(discountActions.getListDiscountFailed());
  }
}

function* handleGetListEmployee(action) {
  try {
    const res = yield call(getListEmployee, action.payload);
    if (!res) return yield put(discountActions.getListEmployeeFailed());
    yield put(discountActions.getListEmployeeSuccess(res.data));
  } catch (error) {
    yield put(discountActions.getListEmployeeFailed());
  }
}

export default function* importWareHouseSaga() {
  yield all([takeLatest(discountActions.getListDiscount.type, handleGetListDiscount)]);
  yield all([takeLatest(discountActions.getListEmployee.type, handleGetListEmployee)]);
}
