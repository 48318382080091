import { createGlobalStyle, css } from 'styled-components';
import Right from 'assets/images/login/Right.png';
import BackgroundLeft from 'assets/images/login/backgroundLeft.svg';
import RightLightRegister from 'assets/images/register/RightLightRegister.svg';
import LeftRegister from 'assets/images/register/LeftRegister.svg';
import Styled from 'theme';

const GlobalStyle = createGlobalStyle`${css`
  ${Styled}
  :root {
    --color-active: #fff;
    --color: red;
    --background-layout: #eceff1;
    --backgroud-main: #ffffff;
    --color-text-main: #000000;
    --color-text-input-table-lading: #000000;
    --color-text-input-table-lading-focus: #000;
    --color-primary: #ff541a;
    --background-arrow-popover: rgba(255, 255, 255, 1);
    --input-error-border: rgba(255, 84, 26, 1);

    //table
    --table-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --table-header-background: #ffffff;
    --table-header-text: #374151;
    --table-cell-text: rgba(46, 64, 92, 1);
    --table-cell-odd-background: #ffffff;
    --table-cell-even-background: #f6f6f6;

    --table-footer-background: #ffffff;
    --table-footer-border: #eceff1;
    --table-footer-display: #7b8190;
    --table-footer-pagination-page-size: #5c68f3;
    --table-footer-pagination-page-size-change: #7b8190;
    --table-footer-pagination-select-page-background: #ffffff;
    --table-footer-pagination-select-page-background__active: #5c68f3;
    --table-footer-pagination-select-page-border: rgba(236, 239, 241, 1);
    --table-footer-pagination-select-text: rgba(123, 129, 144, 1);
    --table-footer-pagination-select-text__active: #ffffff;
    --table-footer-pagination-select-page-icon: #7b8190;
    --table-scrollbar: #613eea;
    --table-placeholder: #eceff1;

    //right panel
    --right-panel-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --right-panel-border: 1px solid rgba(236, 239, 241, 1);

    // STORE
    --store-background-tab: #eceff1;
    --store-backgroud-tab-active: #ff400069;
    --store-backgroud-tab-active-efecto: #fd5219;
    --store-tab-text: rgba(98, 108, 132, 1);
    --store-tab-text-active: #417ff6;

    --store-search-boxshadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --store-search-background-2: #fff;
    --store-search-background: linear-gradient(180deg, #96a5ef 0%, #ffffff 100%);
    --store-search-placeholder: #626c84;
    --store-search-border: rgba(242, 244, 246, 1);
    --store-search-input-boxshadow: 14px 17px 40px 4px #7090b014;
    --store-search-input-background: rgba(244, 247, 254, 1);
    --store-search-input-icon: rgba(98, 108, 132, 1);
    --store-search-date-background: rgba(245, 248, 251, 1);
    --store-search-date-header-background: rgba(236, 239, 241, 1);
    --store-search-date-tab: rgba(55, 65, 81, 1);
    --store-search-date-tab_active: rgba(60, 92, 248, 1);
    --store-search-date-select: rgba(245, 248, 251, 1);
    --store-search-date-icon-down: #7b8190;
    --store-search-date-icon-calendar: #5b73e8;
    --store-search-date-detail-border: #eceff1;
    --store-search-date-detail-box-shadow: 0px 4px 4px 0px #00000040;
    --store-search-date-detail-icon-box: #0f658a;
    --store-search-date-detail-icon-weigh: #5b73e8;
    --store-search-date-detail-text-bill: #5b73e8;
    --store-search-date-detail-text-total: #8d41ee;
    --store-search-date-detail-footer: rgba(236, 239, 241, 1);
    --store-search-date-detail-footer-up: #239e71;
    --store-search-date-detail-footer-down: #f46a6a;
    --search-date-border-right: rgba(236, 239, 241, 1);
    --store-search-commission-border: rgba(236, 239, 241, 1);
    --store-search-commission-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --store-search-commission-paid-background: rgba(240, 249, 255, 1);
    --store-search-commission-wait-pay: rgba(105, 102, 255, 1);
    --store-search-commission-paid: rgba(68, 74, 109, 1);
    --store-search-date-commission-text: rgba(141, 65, 238, 1);
    --store-search-date-commission-discount: rgba(147, 63, 16, 1);
    --store-search-date-commission-backgorund: rgba(255, 255, 255, 1);
    --store-search-date-commission-request-icon: rgba(255, 255, 255, 1);

    --store-search-pay-background: #ffffff;
    --store-search-pay-border: 1px solid rgba(236, 239, 241, 1);
    --store-search-pay-total: rgba(72, 70, 204, 1);
    --store-search-pay-money: rgba(109, 48, 232, 1);
    --store-search-pay-discount: #000000;
    --store-search-pay-VND: rgba(0, 0, 0, 1);
    --store-search-pay-primary: #ff541a;
    --store-search-triangle: rgba(72, 70, 204, 1);
    --store-search-status-view-detail: rgba(46, 64, 92, 1);

    --store-lading-status-header: rgba(15, 101, 138, 1);
    --store-lading-status-add-new: rgba(15, 101, 138, 1);
    --store-lading-status-item: rgba(255, 255, 255, 1);
    --store-lading-status-item-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --store-lading-status-not-sign-yet: rgba(0, 149, 255, 1);
    --store-lading-status-signed: rgba(255, 84, 26, 1);
    --store-lading-status-out-store: rgba(136, 77, 255, 1);
    --store-lading-status-checking: rgba(255, 84, 26, 1);
    --store-lading-status-delivered: rgba(68, 74, 109, 1);
    --store-lading-status-come-to-vn: rgba(255, 143, 13, 1);
    --store-lading-input-link: rgba(91, 115, 232, 1);
    --store-lading-box-icon: rgba(15, 101, 138, 1);
    --store-lading-total-money-icon: rgba(141, 65, 238, 1);
    --store-lading-text: rgba(91, 115, 232, 1);
    --store-lading-total-money-text: rgba(141, 65, 238, 1);
    --store-lading-number: rgba(0, 0, 0, 1);
    --store-lading-total-money-number: rgba(141, 65, 238, 1);
    --store-delivered-status-text: rgba(58, 64, 78, 1);
    --store-delivered-status-value: rgba(244, 106, 106, 1);
    --store-delivered-status-value__received: rgba(94, 40, 211, 1);
    --store-delivered-total: rgba(92, 104, 243, 1);
    // LOGIN
    --login-close-rect: #f3f6f9;
    --login-close-path: #7b8190;
    --login-text: rgba(255, 255, 255, 1);
    --login-register-account: rgba(250, 240, 3, 1);
    --login-background: #fff;
    --login-input-background: rgba(255, 255, 255, 1);
    --login-input-border: rgba(215, 220, 224, 1);
    --login-input-border__active: rgba(116, 127, 245, 1);
    --login-input-placeholder: rgba(127, 130, 146, 1);
    --login-input-icon: rgba(123, 129, 144, 1);
    --login-icon-hide-password: rgba(119, 119, 119, 1);
    --login-forgot-password: rgba(46, 64, 92, 1);
    --login-slider-background: rgba(246, 246, 246, 1);
    --login-slider-dot: rgba(255, 64, 0, 1);
    --login-slider-text: rgba(43, 46, 59, 1);
    --login-slider-icon: rgba(255, 255, 255, 1);
    --login-confirm-background: rgba(253, 116, 1, 1);
    --login-confirm-text: rgba(255, 255, 255, 1);
    --login-right-background-image: url(${Right});
    --login-left-background-image: url(${BackgroundLeft});
    --login-title: rgba(46, 64, 92, 1);
    //REGISTER
    --register-select: rgba(91, 115, 232, 1);

    // FORGOT PASSWORD

    --forgot-pass-text: #ffffff;
    --forgot-pass-description: #2b2e3b;
    --forgot-pass-resend: #5c68f3;

    // REGISTER

    --register-background: rgba(238, 112, 69, 1);
    --register-right-background-image: url(${RightLightRegister});
    --register-left-background-image: url(${LeftRegister});
    --register-left-logo: rgba(255, 255, 255, 1);
    --register-left-text: rgba(255, 239, 164, 1);
    --register-rules: rgba(43, 46, 59, 1);
    --register-rules-highlight: rgba(92, 104, 243, 1);
    --register-select-country: rgba(92, 104, 243, 1);
    // RULES

    --rules-main-text: rgba(92, 104, 243, 1);
    --rules-reject-text: rgba(255, 84, 26, 1);
    --reles-reject-background: rgba(237, 237, 237, 1);
    --rules-main-background: rgba(255, 255, 255, 1);
    --rules-view-background: rgba(217, 217, 217, 0.48);
    --rules-agree-text: rgba(255, 255, 255, 1);
    --reles-agree-background: rgba(116, 127, 245, 1);

    // CALENDAR

    --calendar-background: rgba(91, 115, 232, 1);
    --calendar-view-background: rgba(255, 255, 255, 1);
    --calendar-view-border: rgba(204, 204, 204, 1);
    --calendar-note-text: rgba(255, 255, 255, 1);
    --calendar-month-text: rgba(115, 115, 115, 1);
    --calendar-icon: rgba(204, 204, 204, 1);
    --calendar-week: rgba(115, 115, 115, 1);
    --calendar-normal-date: rgba(68, 68, 68, 1);
    --calendar-disabled-date: rgba(221, 221, 221, 1);
    --calendar-selected-date: rgba(255, 255, 255, 1);
    --calendar-selected-background: rgba(151, 208, 195, 1);
    --calendar-selected-background-blur: rgba(174, 226, 214, 1);
    --calendar-today-border: rgba(204, 204, 204, 1);
    --calendar-day-background: #fff;

    // DEPOT
    --depot-border-container: 0.5px solid rgba(227, 227, 227, 1);
    --depot-background: rgba(255, 255, 255, 1);
    --depot-border: rgba(236, 239, 241, 1);
    --depot-color: rgba(91, 115, 232, 1);
    --depot-icon: rgba(255, 84, 26, 1);
    --depot-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    // EMPLOYEE

    --employee-header: rgba(123, 129, 144, 1);

    // SEARCH_BILL
    --search-bill-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-background: rgba(44, 43, 43, 1);
    --search-bill-text: rgba(91, 115, 232, 1);
    --search-bill-code: rgba(253, 232, 37, 1);
    --search-bill-close-background: rgba(253, 112, 65, 1);
    --search-bill-close-icon: rgba(255, 255, 255, 1);
    --search-bill-copy-icon: rgba(255, 255, 255, 1);
    --search-bill-footer: rgba(236, 239, 241, 1);
    --search-bill-footer-text: rgba(91, 115, 232, 1);
    --search-bill-content: rgba(255, 255, 255, 1);
    --search-bill-no-data: rgba(255, 84, 26, 1);
    --search-bill-suggest-box: rgba(253, 112, 65, 1);
    --search-bill-suggest: rgba(255, 255, 255, 1);
    --search-bill-suggest-wrapper: rgba(215, 220, 224, 0.4);
    --search-bill-suggest-wrapper-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-suggest-text: rgba(0, 0, 0, 1);
    --search-bill-suggest-text__active: rgba(255, 64, 0, 1);
    --search-bill-reason-return-box: rgba(91, 115, 232, 1);
    --search-bill-reason-return: rgba(172, 189, 222, 0.4);
    --search-bill-reason-return-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-reason-copy: rgba(91, 115, 232, 1);
    --search-bill-details-time: rgba(85, 90, 104, 1);
    --search-bill-details-item-border: rgba(236, 239, 241, 1);
    --search-bill-details-item-dot: rgba(255, 59, 48, 1);
    --search-bill-status: rgba(58, 59, 63, 1);
    --search-bill-status-name: rgba(58, 59, 63, 1);
    --search-bill-status-number: rgba(91, 115, 232, 1);

    //HEADER

    --header-background: rgba(255, 255, 255, 1);
    --header-sign-in: rgba(92, 104, 243, 1);
    --header-sign-in-text: rgba(255, 255, 255, 1);
    --header-sign-up-text: rgba(92, 104, 243, 1);
    --header-icon-notify-background: rgba(236, 239, 241, 1);
    --header-icon-notify: rgba(123, 129, 144, 1);
    --header-icon-shopping-background: rgba(236, 239, 241, 1);
    --header-icon-shopping: rgba(92, 104, 243, 1);
    --header-badge-background: rgba(244, 106, 106, 1);
    --header-badge-text: rgba(255, 255, 255, 1);

    // MODAL

    --modal-insert-background: rgba(255, 255, 255, 1);
    --modal-insert-header: rgba(91, 115, 232, 1);
    --modal-insert-note-background: rgba(236, 239, 241, 1);
    --modal-insert-note-text: rgba(25, 25, 25, 0.7);
    --modal-insert-button-cancel: rgba(92, 104, 243, 1);
    --modal-insert-button-insert: rgba(92, 104, 243, 1);

    --modal-delete-text: rgba(43, 46, 59, 1);
    --modal-delete-background: rgba(255, 255, 255, 1);
    --modal-delete-background-warning: rgba(254, 232, 230, 1);

    // MENU
    --menu-icon: rgba(85, 91, 109, 1);
    --menu-background: rgba(236, 239, 241, 1);
    --menu-background-active: rgba(172, 189, 222, 1);
    --menu-icon-active: rgba(244, 106, 106, 1);

    // USER_SETTING
    --user-setting-background: rgba(255, 255, 255, 1);
    --user-setting-username: rgba(0, 0, 0, 1);
    --user-setting-avatar-border: rgba(248, 249, 250, 1);
    --user-setting-id: rgba(58, 64, 78, 1);
    --user-setting-main: rgba(236, 239, 241, 1);
    --user-setting-icon: rgba(34, 34, 34, 1);
    --user-setting-menu: rgba(74, 85, 211, 1);
    --user-setting-language-tick: rgba(60, 127, 243, 1);
    --user-setting-language-border: rgba(236, 239, 241, 1);
    --user-setting-language-icon: rgba(75, 75, 75, 1);
    --user-setting-logout: rgba(232, 45, 45, 1);
    --user-setting-switch-checked: rgba(91, 115, 232, 1);
    --user-setting-switch-checked-handle: rgba(255, 255, 255, 1);
    --user-setting-switch-unchecked: rgba(234, 234, 234, 1);
    --user-setting-switch-unchecked-handle: rgba(254, 143, 1, 1);
    --user-icon-1: #33363f;
    --user-icon-2: #222222;
    --user-icon-3: #7e869e;

    // NOTE_ TOOLTIP
    --tooltip-note-bg: rgba(255, 255, 255, 1);
    --tooltip-note-border: rgba(236, 239, 241, 1);
    --tooltip-note-box-shadow: 0px 5px 60px 0px rgba(0, 0, 0, 0.12);
    --tooltip-note-icon: rgba(91, 115, 232, 1);
    --tooltip-note-text: rgba(94, 94, 94, 1);
    --tooltip-d1: rgba(46, 64, 92, 1);

    //ADDRESS

    --address-setting-background: #fff;
    --address-setting-title: rgba(46, 64, 92, 1);
    --address-setting-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --address-setting-select-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --address-setting-select-header: rgba(236, 239, 241, 1);
    --address-setting-select-header-text: rgba(91, 115, 232, 1);
    --address-setting-select-header-icon: rgba(123, 129, 144, 1);
    --address-setting-select-border: 1px solid rgba(236, 239, 241, 1);
    --address-setting-selected: rgba(244, 106, 106, 1);
    --address-setting-select-background: #fff;
    --address-setting-close-rect: rgba(243, 246, 249, 1);
    --address-setting-close-path: rgba(123, 129, 144, 1);
    --address-setting-confirm: rgba(255, 64, 0, 0.9);
    --address-setting-confirm-text: rgba(255, 255, 255, 1);

    // ACCOUNT_INFO

    --account-info-background: rgba(255, 255, 255, 1);
    --account-info-main-text: rgba(91, 115, 232, 1);
    --account-info-main-text-1: rgba(91, 115, 232, 1);
    --account-info-icon-edit: rgba(255, 84, 26, 1);
    --account-info-note-text: rgba(0, 0, 0, 0.7);
    --account-info-name: rgba(123, 129, 144, 1);
    --account-info-level-color: #fff;
    --account-info-level-background: rgba(91, 115, 232, 1);
    --account-info-form-header-border: 1px solid rgba(236, 239, 241, 1);
    --account-info-form-border: 1px solid #CDD5F6;
    --account-info-form-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --account-info-form-input: rgba(46, 64, 92, 1);
    --account-info-prefix-background: rgba(248, 249, 251, 1);
    --account-info-prefix-border-right: 0.5px solid #CDD5F6;
    --account-input-suffix: rgba(123, 129, 144, 1);
    --account-input-placeholder: rgba(123, 129, 144, 1);
    --account-text-rates: rgba(0, 0, 0, 0.8);
    --account-text-m3: rgba(141, 65, 238, 1);
    --account-text-dd: rgba(255, 84, 26, 1);
    --account-line: rgba(236, 239, 241, 1);
    --account-private-address-color-1: rgba(43, 46, 59, 1);
    --account-private-address-color-2: rgba(255, 84, 26, 1);
    --account-private-address-color-3: rgba(91, 115, 232, 1);
    --account-private-address-color-4: rgba(95, 100, 113, 1);
    --account-private-address-color-5: rgba(248, 249, 251, 1);
    --account-input-background: #fff;
    --acount-form-background: #fff;
    --account-color-no-code: rgba(123, 129, 144, 1);

    // STORE_UPDATE

    --store-tab-border-bottom: rgba(242, 244, 246, 1);
    //--store-tab-active: rgba(97, 62, 234, 1);
    --store-tab-active: #613eea00;
    --store-tab-icon: rgba(98, 108, 132, 1);
    --search-status-footer-icon-export: rgba(91, 115, 232, 1);
    --search-status-footer-delivery-text: #fff;
    --search-status-footer-delivery-background: rgba(255, 84, 26, 1);

    --total-fee-delivery-text: rgba(105, 102, 255, 1);
    --total-fee-delivery-value-d: rgba(55, 65, 81, 1);
    --total-fee-delivery-value: rgba(141, 65, 238, 1);
    --dropdown-lading-border: rgba(236, 239, 241, 1);
    --dropdown-lading-background: #fff;
    --dropdown-lading-color: rgba(91, 115, 232, 1);

    // WithDrawCommission

    --with-draw-background: rgba(236, 239, 241, 1);
    --with-draw-header: rgba(58, 64, 78, 1);
    --with-draw-second-background: rgba(217, 217, 217, 1);
    --with-draw-second-text: rgba(253, 116, 1, 1);
    --with-draw-button: rgba(46, 64, 92, 1);
    --with-draw-main-text: #fff;
    --with-draw-show: rgba(217, 217, 217, 0.8);
    --with-draw-prefix-input: rgba(248, 249, 251, 1);
    --with-draw-prefix-input-placeholder: rgba(127, 130, 146, 1);

    //commission

    --commission-header: #f4f7fe;
    --commission-text: rgba(58, 64, 78, 1);

    //home
    --home-header-text-1: rgba(46, 64, 92, 1);
    --home-header-text-shadow-1: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --home-header-text-2: rgba(255, 255, 255, 1);
    --home-content-text: rgba(112, 117, 132, 1);
    --home-content-text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --home-content-quote1: #fff;
    --home-content-quote2: rgba(254, 143, 1, 1);
    --home-content-quote2-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --home-content-zalo-bg: rgba(255, 255, 255, 1);
    --home-content-zalo-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --home-content-zalo: rgba(46, 64, 92, 1);
    --home-content-zalo-border: rgba(255, 84, 26, 1);
    --home-content-zalo1: rgba(91, 115, 232, 1);
    --home-content-zalo2: rgba(46, 64, 92, 1);
    --home-benefit: rgba(46, 64, 92, 1);
    --home-benefit-border: rgba(255, 84, 26, 1);
    --home-benefit-item: rgba(245, 248, 251, 1);
    --home-benefit-item-active: rgba(251, 245, 250, 1);
    --home-benefit-item-title: rgba(92, 104, 243, 1);
    --home-benefit-item-content: rgba(46, 64, 92, 1);
    --home-benefit-slide: rgba(245, 248, 251, 1);
    --home-contact: rgba(245, 248, 251, 1);
    --home-contact-left-1: rgba(253, 116, 1, 1);
    --home-contact-left-2: rgba(0, 0, 0, 1);
    --home-contact-left-3: rgba(55, 65, 81, 1);
    --home-contact-center: rgba(253, 116, 1, 1);
    --home-contact-right-1: rgba(253, 116, 1, 1);
    --home-contact-right-2: rgba(0, 0, 0, 1);
  }
`}`;

export default GlobalStyle;
