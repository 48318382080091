import { createGlobalStyle, css } from 'styled-components';
import LeftDark from 'assets/images/login/LeftDark.svg';
import RightDark from 'assets/images/login/RightDark.png';
import RightDarkRegister from 'assets/images/register/RightDarkRegister.svg';
import LeftDarkRegister from 'assets/images/register/LeftDark.svg';
import Styled from 'theme';

const GlobalStyle = createGlobalStyle`${css`
  ${Styled}
  :root {
    --color-active: #fff;
    --color: green;
    --background-layout: rgba(24, 25, 26, 1);
    --backgroud-main: #2c2b2b;
    --color-text-main: #fff;
    --color-text-input-table-lading: #fff;
    --color-text-input-table-lading-focus: rgba(127, 130, 146, 1);
    --color-primary: #ff541a;
    --background-arrow-popover: rgba(43, 46, 59, 1);
    --input-error-border: rgba(255, 102, 52, 1);

    //table
    --table-box-shadow: 0px 4px 4px 0px rgba(236, 239, 241, 0.25);
    --table-header-background: #2c2b2b;
    --table-header-text: #bbc5d5;
    --table-cell-text: #ffffff;
    --table-cell-odd-background: #2c2b2b;
    --table-cell-even-background: #161616;

    --table-footer-background: rgba(44, 43, 43, 1);
    --table-footer-border: #191e22;
    --table-footer-display: #7b8190;
    --table-footer-pagination-page-size: rgba(244, 106, 106, 1);
    --table-footer-pagination-page-size-change: #7b8190;
    --table-footer-pagination-select-page-background: #0d0d0d;
    --table-footer-pagination-select-page-background__active: #747ff5;
    --table-footer-pagination-select-page-border: rgba(97, 98, 98, 1);
    --table-footer-pagination-select-text: rgba(255, 255, 255, 1);
    --table-footer-pagination-select-text__active: #0d0d0d;
    --table-footer-pagination-select-page-icon: #7b8190;
    --table-scrollbar: #787c86;
    --table-placeholder: #3c3c3c;
    --table-border: 0.5px solid rgba(68, 68, 68, 1);

    //right panel
    --right-panel-box-shadow: 0px 4px 4px 0px rgba(236, 239, 241, 0.25);
    --right-panel-border: 0.5px solid rgba(68, 68, 68, 1);

    // STORE
    --store-background-tab: #b5b8bb;
    --store-backgroud-tab-active: #ff531a69;
    --store-backgroud-tab-active-efecto: #fd6532;
    --store-tab-text: #fff;
    --store-tab-text-active: #5991f7;

    --store-search-boxshadow: 0px 2px 2px 0px rgba(255, 255, 255, 0.25);

    --store-search-background-2: #2c2b2b;
    --store-search-background: #2c2b2b;
    --store-search-placeholder: #ffffff;
    --store-search-border: rgba(55, 65, 81, 1);
    --store-search-input-boxshadow: 14px 17px 40px 4px #7090b014;
    --store-search-input-background: #374151;
    --store-search-input-icon: #ffffff;
    --store-search-date-background: rgba(55, 65, 81, 1);
    --store-search-date-header-background: rgba(49, 54, 57, 1);
    --store-search-date-tab: rgba(255, 255, 255, 1);
    --store-search-date-tab_active: #ffffff;
    --store-search-date-select: rgba(55, 65, 81, 1);
    --store-search-date-icon-down: rgba(255, 255, 255, 1);
    --store-search-date-icon-calendar: #ffffff;
    --store-search-date-detail-border: none;
    --store-search-date-detail-box-shadow: 0px 4px 4px 0px rgba(236, 239, 241, 0.25);
    --store-search-date-detail-icon-box: rgba(42, 198, 112, 1);
    --store-search-date-detail-icon-weigh: rgba(255, 98, 82, 1);
    --store-search-date-detail-text-bill: rgba(42, 198, 112, 1);
    --store-search-date-detail-text-total: rgba(255, 98, 82, 1);
    --store-search-date-detail-footer: rgba(236, 239, 241, 1);
    --store-search-date-detail-footer-up: #239e71;
    --store-search-date-detail-footer-down: #f46a6a;
    --search-date-border-right: rgba(236, 239, 241, 1);

    --store-search-commission-border: rgba(49, 54, 57, 1);
    --store-search-commission-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --store-search-commission-paid-background: rgba(49, 54, 57, 1);
    --store-search-commission-wait-pay: rgba(105, 102, 255, 1);
    --store-search-commission-paid: rgba(254, 143, 1, 1);
    --store-search-date-commission-text: rgba(254, 143, 1, 1);
    --store-search-date-commission-discount: #fff;
    --store-search-date-commission-backgorund: rgba(55, 65, 81, 1);
    --store-search-date-commission-request-icon: rgba(13, 13, 13, 1);

    --store-search-pay-background: rgba(49, 54, 57, 1);
    --store-search-pay-border: 0.5px solid rgba(55, 65, 81, 1);
    --store-search-pay-total: rgba(255, 255, 255, 1);
    --store-search-pay-money: rgba(254, 143, 1, 1);
    --store-search-pay-discount: rgba(255, 255, 255, 1);
    --store-search-pay-VND: rgba(254, 143, 1, 1);
    --store-search-pay-primary: rgba(255, 84, 26, 1);
    --store-search-triangle: rgba(91, 115, 232, 1);
    --store-search-status-view-detail: rgba(46, 64, 92, 1);

    --store-lading-status-header: rgba(255, 255, 255, 1);
    --store-lading-status-add-new: rgba(15, 101, 138, 1);
    --store-lading-status-item: rgba(49, 54, 57, 1);
    --store-lading-status-item-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --store-lading-status-not-sign-yet: rgba(26, 159, 255, 1);
    --store-lading-status-signed: rgba(255, 84, 26, 1);
    --store-lading-status-out-store: rgba(136, 77, 255, 1);
    --store-lading-status-checking: rgba(255, 84, 26, 1);
    --store-lading-status-delivered: rgba(52, 195, 143, 1);
    --store-lading-status-come-to-vn: rgba(255, 154, 39, 1);
    --store-lading-input-link: rgba(91, 115, 232, 1);
    --store-lading-box-icon: rgba(255, 255, 255, 1);
    --store-lading-total-money-icon: rgba(254, 143, 1, 1);
    --store-lading-text: rgba(254, 143, 1, 1);
    --store-lading-total-money-text: rgba(255, 255, 255, 1);
    --store-lading-number: rgba(255, 255, 255, 1);
    --store-lading-total-money-number: rgba(255, 255, 255, 1);
    --store-delivered-status-text: rgba(254, 143, 1, 1);
    --store-delivered-status-value: rgba(255, 255, 255, 1);
    --store-delivered-status-value__received: rgba(255, 255, 255, 1);
    --store-delivered-total: #fff;

    // LOGIN
    --login-close-rect: rgba(123, 129, 144, 1);
    --login-close-path: #ffffff;
    --login-text: rgba(255, 255, 255, 1);
    --login-register-account: rgba(252, 244, 27, 1);
    --login-background: rgba(43, 46, 59, 1);
    --login-input-background: transparent;
    --login-input-border: rgba(174, 189, 223, 1);
    --login-input-border__active: rgba(116, 127, 245, 1);
    --login-input-placeholder: rgba(127, 130, 146, 1);
    --login-input-icon: rgba(255, 255, 255, 1);
    --login-icon-hide-password: rgba(255, 255, 255, 1);
    --login-forgot-password: rgba(46, 64, 92, 1);
    --login-slider-background: rgba(123, 129, 144, 1);
    --login-slider-dot: rgba(255, 83, 26, 1);
    --login-slider-text: rgba(255, 255, 255, 1);
    --login-slider-icon: rgba(255, 255, 255, 1);
    --login-confirm-background: rgba(253, 116, 1, 1);
    --login-confirm-text: rgba(255, 255, 255, 1);
    --login-right-background-image: url(${RightDark});
    --login-left-background-image: url(${LeftDark});
    --login-title: rgba(230, 233, 239, 1);

    // FORGOT PASSWORD
    --forgot-pass-text: rgba(255, 255, 255, 1);
    --forgot-pass-description: rgba(255, 255, 255, 1);
    --forgot-pass-resend: rgba(254, 143, 1, 1);

    // REGISTER

    --register-background: rgba(240, 129, 93, 1);
    --register-right-background-image: url(${RightDarkRegister});
    --register-left-background-image: url(${LeftDarkRegister});
    --register-left-logo: rgba(255, 255, 255, 1);
    --register-left-text: rgba(255, 239, 164, 1);
    --register-rules: rgba(144, 161, 242, 1);
    --register-rules-highlight: rgba(255, 255, 255, 1);
    --register-select-country: rgba(230, 233, 239, 1);

    // RULES

    --rules-main-text: rgba(255, 255, 255, 1);
    --rules-reject-text: rgba(255, 84, 26, 1);
    --reles-reject-background: rgba(237, 237, 237, 1);
    --rules-main-background: rgba(43, 46, 59, 1);
    --rules-view-background: rgba(85, 91, 109, 1);
    --rules-agree-text: rgba(255, 255, 255, 1);
    --reles-agree-background: rgba(116, 127, 245, 1);

    // CALENDAR

    --calendar-background: rgba(91, 115, 232, 1);
    --calendar-view-background: rgba(255, 255, 255, 1);
    --calendar-view-border: rgba(204, 204, 204, 1);
    --calendar-note-text: rgba(255, 255, 255, 1);
    --calendar-month-text: rgba(115, 115, 115, 1);
    --calendar-icon: rgba(204, 204, 204, 1);
    --calendar-week: rgba(115, 115, 115, 1);
    --calendar-normal-date: rgba(68, 68, 68, 1);
    --calendar-disabled-date: rgba(221, 221, 221, 1);
    --calendar-selected-date: rgba(255, 255, 255, 1);
    --calendar-selected-background: rgba(151, 208, 195, 1);
    --calendar-selected-background-blur: rgba(174, 226, 214, 1);
    --calendar-today-border: rgba(204, 204, 204, 1);
    --calendar-day-background: #fff;

    // DEPOT
    --depot-border-container: 1px solid rgba(43, 46, 59, 1);
    --depot-background: rgba(25, 30, 34, 1);
    --depot-border: rgba(85, 91, 109, 1);
    --depot-color: rgba(255, 255, 255, 1);
    --depot-icon: rgba(255, 102, 52, 1);
    --depot-box-shadow: 0px 4px 4px 0px rgba(236, 239, 241, 0.25);

    // EMPLOYEE

    --employee-header: rgba(255, 255, 255, 1);

    // SEARCH_BILL
    --search-bill-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-background: rgba(113, 134, 235, 1);
    --search-bill-text: rgba(255, 255, 255, 1);
    --search-bill-code: rgba(254, 234, 62, 1);
    --search-bill-close-background: rgba(253, 131, 90, 1);
    --search-bill-close-icon: rgba(255, 255, 255, 1);
    --search-bill-copy-icon: rgba(255, 255, 255, 1);
    --search-bill-footer: rgba(55, 65, 81, 1);
    --search-bill-footer-text: rgba(254, 155, 26, 1);
    --search-bill-content: rgba(44, 43, 43, 1);
    --search-bill-no-data: rgba(255, 102, 52, 1);
    --search-bill-suggest-box: rgba(253, 131, 90, 1);
    --search-bill-suggest: rgba(25, 30, 34, 1);
    --search-bill-suggest-wrapper: rgba(172, 189, 222, 0.4);
    --search-bill-suggest-wrapper-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-suggest-text: rgba(255, 255, 255, 1);
    --search-bill-suggest-text__active: rgba(254, 155, 26, 1);
    --search-bill-reason-return-box: rgba(113, 134, 235, 1);
    --search-bill-reason-return: rgba(172, 189, 222, 0.4);
    --search-bill-reason-return-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --search-bill-reason-copy: rgba(255, 255, 255, 1);
    --search-bill-details-time: rgba(196, 196, 196, 1);
    --search-bill-details-item-border: rgba(129, 132, 134, 1);
    --search-bill-details-item-dot: rgba(255, 59, 48, 1);
    --search-bill-status: rgba(196, 196, 196, 1);
    --search-bill-status-name: rgba(196, 196, 196, 1);
    --search-bill-status-number: rgba(254, 143, 1, 1);

    //HEADER

    --header-background: rgba(44, 43, 43, 1);
    --header-sign-in: rgba(92, 104, 243, 1);
    --header-sign-in-text: rgba(255, 255, 255, 1);
    --header-sign-up-text: rgba(92, 104, 243, 1);
    --header-icon-notify-background: rgba(187, 197, 213, 1);
    --header-icon-notify: rgba(216, 55, 55, 1);
    --header-icon-shopping-background: rgba(187, 197, 213, 1);
    --header-icon-shopping: rgba(116, 127, 245, 1);
    --header-badge-background: rgba(246, 130, 130, 1);
    --header-badge-text: rgba(255, 255, 255, 1);

    // MODAL

    --modal-insert-background: rgba(13, 13, 13, 1);
    --modal-insert-header: rgba(113, 134, 235, 1);
    --modal-insert-note-background: rgba(25, 30, 34, 1);
    --modal-insert-note-text: rgba(255, 255, 255, 1);
    --modal-insert-button-cancel: rgba(116, 127, 245, 1);
    --modal-insert-button-insert: rgba(116, 127, 245, 1);

    --modal-delete-text: rgba(255, 255, 255, 1);
    --modal-delete-background: rgba(25, 30, 34, 1);
    --modal-delete-background-warning: rgba(55, 65, 81, 1);

    // MENU
    --menu-icon: rgba(85, 91, 109, 1);
    --menu-background: rgba(187, 197, 213, 1);
    --menu-background-active: rgba(250, 250, 250, 1);
    --menu-icon-active: rgba(216, 55, 55, 1);

    // USER_SETTING
    --user-setting-background: rgba(43, 46, 59, 1);
    --user-setting-username: rgba(255, 255, 255, 1);
    --user-setting-avatar-border: rgba(248, 249, 250, 1);
    --user-setting-id: rgba(255, 255, 255, 1);
    --user-setting-main: rgba(85, 91, 109, 1);
    --user-setting-icon: rgba(221, 221, 221, 1);
    --user-setting-menu: rgba(255, 255, 255, 1);
    --user-setting-language-tick: rgba(84, 143, 244, 1);
    --user-setting-language-border: rgba(85, 91, 109, 1);
    --user-setting-language-icon: rgba(255, 255, 255, 1);
    --user-setting-logout: rgba(234, 68, 68, 1);
    --user-setting-switch-checked: rgba(113, 134, 235, 1);
    --user-setting-switch-checked-handle: rgba(255, 255, 255, 1);
    --user-setting-switch-unchecked: rgba(234, 234, 234, 1);
    --user-setting-switch-unchecked-handle: rgba(254, 155, 26, 1);
    --user-icon-1: #fff;
    --user-icon-2: #fff;
    --user-icon-3: #fff;

    // NOTE_ TOOLTIP
    --tooltip-note-bg: rgba(44, 43, 43, 1);
    --tooltip-note-border: unset;
    --tooltip-note-box-shadow: 0px 5px 40px 0px rgba(236, 239, 241, 0.25);
    --tooltip-note-icon: rgba(255, 255, 255, 1);
    --tooltip-note-text: rgba(215, 220, 232, 1);
    --tooltip-d1: #fff;

    //ADDRESS

    --address-setting-background: rgba(43, 46, 59, 1);
    --address-setting-title: rgba(255, 255, 255, 1);
    --address-setting-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --address-setting-select-shadow: 0px 4px 4px 0px transparent;
    --address-setting-select-header: rgba(44, 43, 43, 1);
    --address-setting-select-header-text: #fff;
    --address-setting-select-header-icon: rgba(123, 129, 144, 1);
    --address-setting-select-border: 0.5px solid rgba(85, 91, 109, 1);
    --address-setting-selected: rgba(246, 130, 130, 1);
    --address-setting-select-background: rgba(25, 30, 34, 1);
    --address-setting-close-rect: transparent;
    --address-setting-close-path: #fff;
    --address-setting-confirm: rgba(255, 64, 0, 0.9);
    --address-setting-confirm-text: rgba(255, 255, 255, 1);

    // ACCOUNT_INFO

    --account-info-background: rgba(43, 46, 59, 1);
    --account-info-main-text: #fff;
    --account-info-main-text-1: rgba(255, 84, 26, 1);
    --account-info-icon-edit: rgba(255, 84, 26, 1);
    --account-info-note-text: rgba(255, 255, 255, 0.7);
    --account-info-name: #fff;
    --account-info-level-color: rgba(91, 115, 232, 1);
    --account-info-level-background: #fff;
    --account-info-form-header-border: 1px solid #fff;
    --account-info-form-border: 1px solid transparent;
    --account-info-form-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    --account-info-form-input: #fff;
    --account-info-prefix-background: rgba(43, 46, 59, 1);
    --account-info-prefix-border-right: 0.5px solid rgba(174, 189, 223, 1);
    --account-input-suffix: rgba(255, 255, 255, 0.8);
    --account-input-placeholder: #fff;
    --account-text-rates: #fff;
    --account-text-m3: #fff;
    --account-text-dd: rgba(255, 84, 26, 1);
    --account-line: rgba(236, 239, 241, 1);
    --account-private-address-color-1: #fff;
    --account-private-address-color-2: rgba(255, 84, 26, 1);
    --account-private-address-color-3: rgba(255, 84, 26, 1);
    --account-private-address-color-4: rgba(95, 100, 113, 1);
    --account-private-address-color-5: rgba(248, 249, 251, 1);
    --account-input-background: rgba(43, 46, 59, 1);
    --acount-form-background: rgba(25, 30, 34, 1);
    --account-color-no-code: rgba(123, 129, 144, 1);

    // STORE_UPDATE

    --store-tab-border-bottom: rgba(98, 108, 132, 1);
    --store-tab-active: rgba(254, 143, 1, 1);
    --store-tab-icon: #fff;
    --search-status-footer-icon-export: rgba(244, 247, 254, 1);
    --search-status-footer-delivery-background: rgba(244, 247, 254, 1);
    --search-status-footer-delivery-text: rgba(255, 84, 26, 1);

    --total-fee-delivery-text: rgba(105, 102, 255, 1);
    --total-fee-delivery-value-d: #fff;
    --total-fee-delivery-value: rgba(141, 65, 238, 1);
    --dropdown-lading-border: #161616;
    --dropdown-lading-background: #161616;
    --dropdown-lading-color: rgba(91, 115, 232, 1);
  }
`}`;

export default GlobalStyle;
