import Services from './index';

const getListCountry = async () => await Services.get('/api/Nation/getNations');
const getListCity = async (params) => await Services.get('/api/Nation/getCityByNation', { params });
const getListDistrict = async (params) =>
  await Services.get('/api/Nation/getDistrictByCity', { params });
const getListWard = async (params) =>
  await Services.get('/api/Nation/getWardByDistrict', { params });

export { getListCountry, getListCity, getListDistrict, getListWard };
