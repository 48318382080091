import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getListDelivered, excelDelivered, getStatistic } from 'services/deliveredService';
import moment from 'moment';
import { deliveredActions } from 'redux/deliveredSlice';
import { getAccountBalance } from 'services/commisionsService';
import { t } from 'i18next';
import { isNumber } from 'lodash';

function* handleGetList(action) {
  try {
    const [res, resStatistic, resBalance] = yield all([
      call(getListDelivered, action.payload),
      action.payload?.pageIndex === 1 && call(getStatistic, action.payload),
      action.payload?.pageIndex === 1 && call(getAccountBalance),
    ]);
    if (!res) yield put(deliveredActions.getListDeliveredFailed());
    if (resStatistic) {
      const statistic = resStatistic?.data;
      yield put(deliveredActions.getStatistic(statistic));
    }
    if (resBalance) yield put(deliveredActions.getBalance(resBalance?.data));
    const data = { ...res?.data };
    if (isNumber(resStatistic?.data?.Total)) data.Total = resStatistic?.data?.Total;
    yield put(deliveredActions.getListDeliveredSuccess(data));
  } catch (error) {
    yield put(deliveredActions.getListDeliveredFailed());
  }
}

function* exportImport(action) {
  try {
    const res = yield call(excelDelivered, action.payload);
    if (!res) return yield put(deliveredActions.exportdeliveredFailed());
    const href = URL.createObjectURL(res);
    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute(
      'download',
      `${t('store:delivered')}_${moment().format('DD/MM/YYYY HH:MM')}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    yield put(deliveredActions.exportDeliveredSuccess());
  } catch (error) {
    yield put(deliveredActions.exportdeliveredFailed());
  }
}

export default function* deliveredSaga() {
  yield all([takeLatest(deliveredActions.getListDelivered.type, handleGetList)]);
  yield all([takeLatest(deliveredActions.exportDelivered.type, exportImport)]);
}
